import React from "react";
import ApplyDiscount from "./ApplyDiscount";
import { applyDiscountOnLoan, calculateDiscountOnLoan, getDiscountHistory, revertDiscountAction } from "./ApplyDiscountAction";
import { OPERATION_STATUS } from "../../config/axios.init";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import { Strings } from "../../resources/i18n/i18n";
import { Button, Col } from "react-bootstrap";
import { setSearchFilterAction } from "../../constants/Action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ROUTES } from "../../constants/routeConstants";
import withHOC from "../../HOC/HOC";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import { ANALYST_LOAN_DETAIL_FILTER, APPROVAL_DASHBOARD_FILTER, CONFIGURATION_DATE_TIME_FORMAT, DAILY_STATUS_FILTER, FLR_SEARCH_FILTER, LOAN_DETAIL_FILTER, LOAN_PORTFOLIO_FILTER, MINIMART_LIST_FILTER, PERSON_DAILY_STATUS_FILTER, SEARCH_FILTER_PAGES, SLR_SEARCH_FILTER, SPED_L1_FILTER, SPED_L2_FILTER, USERS_WITH_ACCESS_APPLY_DISCOUNT, WORKLOAD_MANAGEMENT_FILTER } from "../../constants/appConstants";
import _ from "lodash";
import { DISCOUNT_HISTORY, DISCOUNT_HISTORY_COLUMNS, DISCOUNT_HISTORY_FIELDS } from "./Constants";
import moment from "moment";
import { getLoanDetailsByIdAction } from "../LoanDetails/LoanDetailsAction";
class ApplyDiscountContainer extends React.Component {
  constructor(props) {
    super(props);
    this._columns = DISCOUNT_HISTORY_COLUMNS;
    this._columns2 = DISCOUNT_HISTORY;
    this.state = {
      loanDetails: null,
      applicationId: props.selectedLoanDetails.applicationId,
      showLoadingModal: false,
      errorMessage: "",
      calculatedDiscountDetails: "",
      emiDiscount: "",
      discountNote: "",
      showToast: false,
      level: "",
      toastMessage: "",
      discountHistory: "",
      sumKeysArray: DISCOUNT_HISTORY_FIELDS.HISTORY_DATA_FIELDS.sumkeyArray,
      sumRows: [],
    };
  }
  componentDidMount() {
    USERS_WITH_ACCESS_APPLY_DISCOUNT.includes(this.props.logInUserInfo.role) && this.getLoanDetailsById(this.props.selectedLoanDetails.applicationId);
  }
  componentWillUnmount() {
    //NOSONAR
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.MINIMART_LIST &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.MINIMART_LIST,
        {
          ...MINIMART_LIST_FILTER,
        }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD,
        {
          ...APPROVAL_DASHBOARD_FILTER,
        }
      );
    }

    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.SPED_L2 &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(SEARCH_FILTER_PAGES.SPED_L1, {
        ...SPED_L1_FILTER,
      });
      this.props.setSearchFilterAction(SEARCH_FILTER_PAGES.SPED_L2, {
        ...SPED_L2_FILTER,
      });
    }
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.LOAN_PORTFOLIO &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_PORTFOLIO,
        { ...LOAN_PORTFOLIO_FILTER }
      );
    }

    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT,
        { ...FLR_SEARCH_FILTER }
      );
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT,
        { ...SLR_SEARCH_FILTER }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.DAILY_STATUS,
        { ...DAILY_STATUS_FILTER }
      );
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS,
        { ...PERSON_DAILY_STATUS_FILTER }
      );
    }

    // loan approval
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.LOAN_DETAIL &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setMinimartDetailsActiveTab(null);
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_DETAIL,
        { ...LOAN_DETAIL_FILTER }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT,
        { ...WORKLOAD_MANAGEMENT_FILTER }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.ANALYST_LOAN_DETAIL &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.ANALYST_LOAN_DETAIL,
        { ...ANALYST_LOAN_DETAIL_FILTER }
      );
    }
  }

  getLoanDetailsById = (applicationId, loanId = null) => {
    this.setState({
      showLoadingModal: true,
    });
    const requestData = {
      applicationId: applicationId,
      loanId: loanId,
      callback: (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState(
            {
              errorMessage: "",
              loanDetails: Response.data.data,
            }
          );
        } else {
          this.setState({
            showLoadingModal: false,
            errorMessage: Response.error.message,
          });
        }
      },
    };
    getLoanDetailsByIdAction(requestData);
  };
  calculateDiscount = (request) => {
    if(request.lid && !request.d){
      return;
    }
    let requestData = {
      lid: this.state.loanDetails.info.id,
      d: request
    }
    this.setState({ showLoadingModal: true });
    calculateDiscountOnLoan(requestData, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
          calculatedDiscountDetails: Response.data.data,
        });
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },

        );
      }
    });
  };

  applyDiscount = (discount, note) => {
    if(!discount && !note){
      return;
    }
    let requestData = {
      lid: this.state.loanDetails.info.id,
      d: discount,
      nt: note
    }
    this.setState({ showLoadingModal: true });
    applyDiscountOnLoan(requestData, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
          toastMessage: Strings("discountAppliedSuccessfully"),
          showToast: true,
          level: "success",
        }, () => {
          setTimeout(() => {
            this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS);
          }, 1000);
        });
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          }

        );
      }
    });
  }

  getDiscountHistory = () => {
    if (!this.props.selectedLoanDetails.loanId) {
      return;
    }
    let requestData = {
      lid: this.props.selectedLoanDetails.loanId,
    }
    this.setState({ showLoadingModal: true });
    getDiscountHistory(requestData, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
          discountHistory: Response.data.data,
        });
        this.createSumRows(Response.data.data, []);
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },

        );
      }
    });
  }

  revertDiscount = (id, note) => {
    let requestData = {
      adid: id,
      lid: this.state.loanDetails.info.id,
      nt: note
    }
    this.setState({ showLoadingModal: true });
    revertDiscountAction(requestData, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
          toastMessage: Strings("discountRevertedSuccessfully"),
          showToast: true,
          level: "success",
        }, () => {
          setTimeout(() => {
            this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS);
          }, 1000);
        });
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },

        );
      }
    });

  }
  createSumRows = (tempRows, rows) => {
    _.forEach(tempRows, (reportsDetails) => {
      let tempObj = {};

      this.state.sumKeysArray.forEach((key) => {
        if (Array.isArray(key) && key.length === 2) {
          const value1 = reportsDetails[key[0]];
          const value2 = reportsDetails[key[1]];
          if (key[0] === "po" && key[1] === "act") {
            tempObj[key[0] + "-" + key[1]] = moment(value1).format(CONFIGURATION_DATE_TIME_FORMAT) + " \n " + Strings(value2);
          } else {
            tempObj[key[0] + "-" + key[1]] = Strings(value1) + " \n " + Strings(value2);
          }
        } else if (key === "dt") {
          const dt = JSON.parse(reportsDetails.dt);
          if (dt.hasOwnProperty('notes')) {
            delete dt.notes;
          }
          tempObj.dt=dt;
        } else {
          tempObj[key] = reportsDetails[key] || 'N/A';
          tempObj["isReverted"] = reportsDetails.act === "MANUAL_DISCOUNT_REVERTED";
        }
      });
      rows.push(tempObj);
    });

    this.setState({ sumRows: rows });
  };

  handleBackButtonClick = () => {
    this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS);
  };
  render() {
    let discountHistoryColumns;
    if (USERS_WITH_ACCESS_APPLY_DISCOUNT.includes(this.props.logInUserInfo.role)) {
      discountHistoryColumns = this._columns;
    }
    else {
      discountHistoryColumns = this._columns2;
    }

    return (
      <div>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        {this.state.showToast && (
          <UserNotification
            userNotificationObj={{
              message: Strings(this.state.toastMessage),
              level: this.state.level,
            }}
          />
        )}
        <ApplyDiscount
          loanDetails={this.state.loanDetails}
          calculateDiscount={this.calculateDiscount}
          rows={this.state.sumRows}
          cols={discountHistoryColumns}
          applyDiscount={this.applyDiscount}
          calculatedDiscountDetails={this.state.calculatedDiscountDetails}
          handleBackButtonClick={this.handleBackButtonClick}
          getDiscountHistory={this.getDiscountHistory}
          revertDiscount={this.revertDiscount}
          uerRole={this.props.logInUserInfo.role}
          ErrorMessageFromResponse={this.state.errorMessage}
        />
        <Col md={1} className='alignCenter ps-0 pt-2'>
          <Button
            className='buttonBorder'
            onClick={this.handleBackButtonClick}
          >
            {Strings("Back").toUpperCase()}
          </Button>
        </Col>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
  selectedLoanDetails: state.loanDetailsReducer.loanDetails,
  logInUserInfo: state.loginReducer.loggedInUserInfo,

});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setSearchFilterAction },
    dispatch
  );
};
const ApplyDiscountWrapper = withHOC(ApplyDiscountContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyDiscountWrapper);