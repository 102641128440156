import { Strings } from "../resources/i18n/i18n";

export const ROUTES = {
  LOGIN: Strings("login"),
  BRANCH_LIST: Strings("branchList"),
  BRANCH_DETAILS: Strings("branchDetails"),
  USER_LIST: Strings("userList"),
  DASHBOARD: Strings("dashboard"),
  MINIMART_MANAGEMENT: Strings("minimart"),
  REPORTS: Strings("reports"),
  DAILY_STATUS: Strings("dailyStatus"),
  CI_INTERFACE: Strings("ciInterface"),
  CI_COLLECTION_APP_LOAN_REQUEST: Strings("ciLoanRequestReport"),
  USER_PROFILE: Strings("userprofile"),
  CHANGE_PASSWORD: Strings("changepassword"),
  MINIMART_DETAILS: Strings("minimartDetails"),
  VERIFY_COLLECTION: Strings("verifyCollection"),
  LOAN_PORTFOLLIO_MANAGEMENT: Strings("loanPortFolio"),
  SALES_PERSON_DAILY_STATUS_DETAILS: Strings(
    "dailyStatusSalesPersonDetails"
  ),
  MESSENGER_DAILY_STATUS_DETAILS: Strings(
    "dailyStatusMessengerDetails"
  ),
  DAILY_STATUS_MESSENGER: Strings("dailyStatusMessenger"),
  DAILY_STATUS_MODERATOR: Strings("dailyStatusModerator"),
  LOAN_APPLICATION_DETAILS: Strings("loanDetail"),
  VIEW_LOAN_APPLICATIONS: Strings("viewLoanApplications"),
  COLLECTION_VERIFICATION: Strings("collectionVerfication"),
  CONFIGURATION_DETAILS: Strings("configurationDetails"),
  EMI: Strings("configurationListEMI"),
  EARLY_PAYMENT: Strings("configurationListEarlyPayment"),
  LOAN_STATUS: Strings("configurationListLoanStatus"),
  EARLY_CANCELLATION: Strings("configurationListEarlyCancellation"),
  MINIMART_GRADE: Strings("configurationListMinimartGrade"),
  LATE_FEE: Strings("configurationListLateFee"),
  LOAN_PICKUP: Strings("configurationListLoanPickup"),
  GRACE_PERIOD: Strings("configurationListGracePeriod"),
  COMMISSION_SCHEME: Strings("CommissionSchemeRoute"),
  USER_COMMISSION: Strings("UserCommissionRoute"),
  COMMISSION_DETAILS: Strings("CommissionDetails"),
  PROVIOSION: Strings("configurationListProvision"),
  HOLIDAY: Strings("configurationHoliday"),
  PAYMENT_DISCOUNT: Strings("configurationPaymentDiscount"),
  ANALYST: Strings("configurationAnalyst"),

  UPCOMING: Strings("upcoming"),
  FILE_CONTENT: Strings("fileContent"),
  WORK_HISTORY: Strings("workHistory"),
  USER_WORK_HISTORY: Strings("userWorkHistory"),
  WORK_HISTORY_SALES_PERSON: Strings("userWorkHistory"),
  SALES_PERSON_WORK_HISTORY: Strings(
    "userWorkHistorySalesPersonDetails"
  ),
  WORK_HISTORY_MESSENGER: Strings("userWorkHistoryMessenger"),
  MESSENGER_WORK_HISTORY: Strings("userWorkHistoryMessengerDetails"),
  WORK_HISTORY_COLLECTIONS_OFFICER: Strings(
    "userWorkHistoryCollectionsOfficer"
  ),
  COLLECTIONS_OFFICER_WORK_HISTORY: Strings(
    "userWorkHistoryCollectionsOfficerDetails"
  ),
  WORKLOAD_MANAGEMENT: Strings("workLoad"),
  MAP: Strings("Map"),
  CIDATA_SCHEDULER: Strings("Scheduler"),
  LOAN_PORTFOLIO_REPORTS: Strings("LoanportfolioReports"),
  SALES_REPORTS: Strings("salesReports"),
  COLLECTIONS_REPORTS: Strings("collectionsReports"),
  WEB_COLLECTIONS_REPORTS: Strings("webCollectionsReports"),
  REPORTS_FOR_SINGLE_LOAN_PORTFOLIO: Strings(
    "singleLoanPortFolioReports"
  ),
  REPORTS_FOR_SINGLE_SALES: Strings("singleSalesReports"),
  REPORTS_FOR_SINGLE_COLLECTIONS: Strings("singleCollectionsReports"),
  REPORTS_FOR_SINGLE_WEB_COLLECTIONS: Strings("singleWebCollectionsReports"),
  THIRD_LEVEL_LOAN_PORTFOLIO: Strings("thirdLevelReports"),
  APPROVAL_DASHBOARD: Strings("approvalDashboard"),
  ASSIGN_REPAYMENT: Strings("repaymentPlan"),
  SALES_COMISSION: Strings("salesPersonComission"),
  MESSENGER_COMISSION: Strings("messengerComission"),
  SALES_MODERATOR_COMISSION: Strings("salesModeratorComssion"),
  MESSENGER_MODERATOR_COMISSION: Strings(
    "messengerModeratorComssion"
  ),
  ELIGIBILITY_CRITERIA: Strings("higherCreditLimit"),
  CREDIT_SCORE: Strings("creditScoreConfigurationList"),
  ANALYST_LOAN_VIEW: Strings("analystLoanView"),
  LOAN_HISTORY: Strings("loanHistoryRoutes"),
  LOAN_APPROVAL_DASHBOARD: Strings("loanApproval"),
  OPERATION_LOAN_APPROVAL_DASHBOARD: Strings("operationLoanApproval"),
  CI_WIRE_TRANSFER: Strings("CIWireTransRoute"),
  PRODUCT_CATALOG: "/productCatalog",
  COMMISSION_REPORT: Strings("commissionReportURL"),
  DETAIL_COMMISSION_REPORT: "/detailCommissionReport",
  CALL_NOTES: "/callNotes",
  MINIMART_APPROVAL_REQUESTS: Strings("minimartApprovalRequests"),
  APPROVAL_MINIMART_HISTORY: Strings("ApprovalMinimartHistory"),
  APPROVAL_MINIMART_DETAILS: Strings("ApprovalMinimartDetails"),
  SINGLE_SALES_PERSON: Strings("singleSalesPerson"),
  SALES_EFFICIENCY_DASHBOARD: Strings(
    "salespersonEfficiencyDashboard"
  ),
  MOTORCYCLE_CATALOGUE: Strings("MotorcycleCatalogue"),
  ZONE_POSITION_MAPPING: Strings("zonePositionMapping"),

  //MTP
  MTP_APPROVAL: Strings("/MTPApproval"),
  MTP_COMPLAINT_PORTAL: Strings("/MTPComplaintPortal"),
  MTP_DASHBOARD: Strings("/MTPDashboard"),
  MTP_LADDER: Strings("/MTPLadder"),
  MTP_PRODUCTS: Strings("/MTPProducts"),
  MTP_PROMOTIONS: Strings("/MTPPromotions"),
  MTP_LOAN_REQUESTS: Strings("/MTPLoanRequests"),
  MTP_SIGNUP_REQUEST: Strings("mtpSignupRequest"),
  MTP_SIGNUP_REQUEST_DETAILS: Strings("mtpSignupRequestDetails"),
  MTP_MINIMART_REQUESTS: Strings("/MTPEditMinimrtRequests"),
  MTP_PROMOTIONS_APPROVAL: Strings("mtpPromotionApproval"),
  MTP_PROMOTION_DETAILS: Strings("mtpPromotionDetails"),

  WALLET_REPORTS: Strings("walletReport"),
  REPORTS_FOR_SINGLE_WALLET: Strings("singleWalletReport"),
  REMITTANCE_PAYMENT: Strings("remittancePayment"),
  NEW_MINIMART_MANAGEMENT: Strings("newMinimart"),
  NEW_CREDIT_SCORE: Strings("configurationNewCreditScore"),

  SPECIAL_SALES_REPORTS: Strings("specialSalesReports"),
  REPORTS_FOR_SINGLE_SPECIAL_SALES: Strings("singleSpecialSReports"),
  REWARD_CATALOG: Strings("rewardCatalog"),
  REWARD_CONFIGURATION: Strings("configurationReward"),
  META_MAP_CONFIGURATION: Strings("configurationMetaMap"),
  REWARD_APPROVAL: Strings("rewardApproval"),
  REWARD_APPROVAL_DETAILS: Strings("rewardApprovalDetails"),
  INVOICE_APPROVAL: Strings("invoiceApproval"),

  PRIVACY_POLICY: Strings("privacyPolicy"),
  CLIENT_PORTAL: Strings("clientPortal"),
  BANK_DETAIL_CONFIGURATION: Strings("bankDetailConfiguration"),
  MANAGE_SUPPLIER: Strings("manageSupplier"),
  ADMINISTRATIVE_EXPENSE_DATA: Strings("configurationAdministrativeExpense"),
  MERGED_MINIMART_DETAILS: Strings("mergedMinimartDetails"),
  NOTE_DETAILS:Strings("notesDetails"),
  APPLY_DISCOUNT:Strings("applyDiscount"),

  CREDO_CONFIG: Strings("configurationListCredoCall"),
  PAYMENT_FREQUENCY: Strings("paymentFrequenciesConfiguration"),
  LOAN_STATUS_AND_GRADE: Strings("gradesConfiguration")
};
