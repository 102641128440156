import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fileDownload from "js-file-download";

//Components
import MinimartList from "./MinimartList";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import withHOC from "../../HOC/HOC";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

//Actions
import {
  getMinimartDataAction,
  setMiniMartDetailsAction,
} from "../CiInterface/CIInterfaceActions";
import {
  dowloadedAction,
  setSearchFilterAction,
} from "../../constants/Action";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";
import { getMinimartBusinessTypes } from "../SuperDashBoard/SuperDashBoardAction";
import { setIsNewMinimartAction } from "../MinimartList/MinimartListActions";

//Constants
import {
  SEARCH_FILTER_PAGES,
  MINIMART_LIST_FILTER,
  NEW_MINIMART_LIST_FILTER,
  WORKLOAD_MANAGEMENT_FILTER,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";
import { OPERATION_STATUS } from "../../config/axios.init";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./MinimartList.scss";

class MinimartListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      isUploadSuccess: false,
      minimarts: [],
      errorMessage: "",
      businessTypeList: [
        {
          key: 0,
          value: "",
          label: Strings("BusinessType"),
        },
      ],
      toShowToast: false,
      message: "",
      level: ""
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    this.props.clearDailyStatusDateAction();
    this.props.getAllZones("SELLER");
    this.props.getAllZones("MESSENGER");
    this.initErrorStates();
    this.getMinimartBusinessTypes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
    this.props.setIsNewMinimartAction(
      this.props.history.location.pathname ===
      ROUTES.NEW_MINIMART_MANAGEMENT
    );
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.NEW_MINIMART_LIST,
        { ...NEW_MINIMART_LIST_FILTER }
      );
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.MINIMART_LIST,
        { ...MINIMART_LIST_FILTER }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT,
        { ...WORKLOAD_MANAGEMENT_FILTER }
      );
    }
  }

  initErrorStates = () => {
    this.setState({ errorMessage: "" });
  };

  getMinimartBusinessTypes = () => {
    this.setState({ showLoader: true });
    getMinimartBusinessTypes((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let list = Utility.createBusinessTypesList(
          response.data.data.bty
        );
        this.setState({
          businessTypeList: list,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        mid: searchFilterObject.search.matricesId,
        code: searchFilterObject.search.ciId,
        mn: searchFilterObject.search.minimartName,
        lvl: searchFilterObject.search.level,
        gr: searchFilterObject.search.grade,
        zn: searchFilterObject.search.zone,
        mzn: searchFilterObject.search.messengerZone,
        toc: searchFilterObject.search.type,
        mst: searchFilterObject.search.supplierType,
        st: searchFilterObject.search.minimartStatus,
        bt: searchFilterObject.search.businessType,
        rs: searchFilterObject.search.registrationStatus,
        limit: searchFilterObject.pagination.limit,
        offset: searchFilterObject.pagination.offset,
        zones: searchFilterObject.search.zones,
        messengerZones: searchFilterObject.search.messengerZones,
        oc: searchFilterObject.search.cedulaId,
        isr: searchFilterObject.search.isScalaRegistered,
        subType: searchFilterObject.search.subType,
      },
    });

    getMinimartDataAction(
      searchFilterObject,
      this.props.history.location.pathname ===
      ROUTES.NEW_MINIMART_MANAGEMENT,

      (responsedata) => {
        if (responsedata.status === OPERATION_STATUS.SUCCESS) {
          const minimarts = responsedata.data.data.minimarts;
          this.setState({
            minimarts,
            numberOfPages: responsedata.data.data.numberOfPages,
            showLoader: false,
            errorMessage: "",
          });
        } else {
          this.setState({
            errorMessage: responsedata.error.message,
            showLoader: false,
          });
        }
      }
    );
  };

  setMiniMartDetails = (minimart) => {
    const serachPage =
      this.props.history.location.pathname ===
        ROUTES.NEW_MINIMART_MANAGEMENT
        ? SEARCH_FILTER_PAGES.NEW_MINIMART_LIST
        : SEARCH_FILTER_PAGES.MINIMART_LIST;
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      serachPage,
      { ...this.state.searchFilter },
      serachPage
    );
    this.props.setMiniMartDetailsAction(minimart);
    this.props.history.push(ROUTES.MINIMART_DETAILS);
  };

  /**
   * Method to download minimarts reports
   * @param {*} url
   * @param {*} requestData
   * @param {*} fileType
   */
  downloadReports = (url, requestData, fileType) => {
    this.setState({
      showLoader: true,
      isError: false,
      toShowToast: false,
      message: "",
      level: ""
    });
    dowloadedAction(
      url,
      requestData,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
            fileDownload(
              response.data,
              Strings("MINIMART_LIST_REPORT") +
              "-" +
              Utility.getReportFormattedDateString(
                Utility.convertDateIntoMiliSeconds(new Date())
              ) +
              Utility.getFileType(fileType)
            );
            this.setState({
              errorMessage: "",
              showLoader: false,
              isError: false,
            });
          } else {
            this.setState({
              showLoader: false,
              isError: true,
              toShowToast: true,
              message: Strings("NoRecordsFound"),
              level: "error",
            });
          }
        } else {
          this.setState({
            showLoader: false,
            errorMessage: response.error.message,
          });
        }
      },
      "MiniMartReports"
    );
  };

  render() {
    const isNewMinimart =
      this.props.history.location.pathname ===
      ROUTES.NEW_MINIMART_MANAGEMENT;
    return (
      <div>
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.message}
            userNotificationObj={{
              message: this.state.message,
              level: this.state.level,
            }}
          />
        )}
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <MinimartList
          minimarts={this.state.minimarts}
          zones={this.props.zones}
          mZones={this.props.mZones}
          errorMessage={this.state.errorMessage}
          initErrorStates={this.initErrorStates}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          numberOfPages={this.state.numberOfPages}
          setMiniMartDetails={this.setMiniMartDetails}
          downloadReports={this.downloadReports}
          searchFilter={
            isNewMinimart
              ? this.props.newMinimartListSearchFilter
              : this.props.minimartListSearchFilter
          }
          businessTypeList={this.state.businessTypeList}
          isNewMinimart={isNewMinimart}
          setIsNewMinimartAction={this.props.setIsNewMinimartAction}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isNewMinimart: state.minimartListReducer.isNewMinimart,
  newMinimartListSearchFilter:
    state.searchFilterReducer.newMinimartListSearchFilter,
  searchPage: state.searchFilterReducer.searchPage,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setMiniMartDetailsAction,
      clearDailyStatusDateAction,
      setSearchFilterAction,
      setIsNewMinimartAction,
    },
    dispatch
  );
};

const MinimartListWrapper = withHOC(MinimartListContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MinimartListWrapper);
