import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";

export const getUserAndMiniMartDataAction = (
  requestData,
  callback
) => {
  let request =
    requestData.branchId +
    "&minimartType=" +
    requestData.minimartType;
  if (requestData.businessType) {
    request += "&businessType=" + requestData.businessType;
  }
  if (requestData.subType) {
    request += "&subType=" + requestData.subType;
  }
  callAPI(
    "getminiMartAndUserChartData",
    API.getminiMartAndUserChartData.concat(request),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getNewUserAndMiniMartDataAction = (
  requestData,
  callback
) => {
  let request =
    requestData.branchId +
    "&minimartType=" +
    requestData.minimartType;
  if (requestData.businessType) {
    request += "&businessType=" + requestData.businessType;
  }
  callAPI(
    "getNewUserAndMiniMartDataAction",
    API.getNewMiniMartAndUserChartData.concat(request),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getInvoiceChartDataAction = (requestData, callback) => {
  callAPI(
    "getInvoiceChartData",
    API.getInvoiceChartData,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getLoanChartDataAction = (requestData, callback) => {
  callAPI(
    "getLoanChartData",
    API.getLoanChartData,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getLoanApplicationChartDataAction = (
  requestData,
  callback
) => {
  callAPI(
    "getLoanChartData",
    API.getLoanDataByCategory,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getAmountDataAction = (requestData, callback) => {
  callAPI(
    "getAmountData",
    API.getAmountData,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getProvisionDataAction = (requestData, callback) => {
  callAPI(
    "getProvisionData",
    API.getProvisionData,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getDenialReasonsDataAction = (request, callback) => {
  callAPI(
    "getDenialReasonsData",
    API.getDenialReasonsData,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getFinanceProfitDataAction = (request, callback) => {
  callAPI(
    "getFinanceProfitData",
    API.getFinanceProfitData,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getMinimartBusinessTypes = (callback) => {
  callAPI(
    "getMinimartBusinessTypes",
    API.getMinimartBusinessTypes,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getPromotionStatsAction = (request, callback) => {
  callAPI(
    "getPromotionStatsAction",
    API.promotionStats,
    "GET",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};
