import { Strings } from "../../resources/i18n/i18n";

export const MINIMART_REPORTS_DOWNLAOD = {
  downloadUrl: "/minimart/download?fileType=",
  newMinimartDownloadUrl: "/minimart/new/download?fileType=",
};

export const IS_SCALA_REGISTERED = [
  {
    key: 0,
    value: "AllMinimarts",
    label: Strings("AllMinimarts"),
  },
  {
    key: 1,
    value: "true",
    label: Strings("scala_registered"),
  },
  {
    key: 2,
    value: "false",
    label: Strings("non_scala_registered"),
  },
]

export const MINIMART_SUB_TYPES = [
  {
    key: 0,
    value: "subType",
    label: Strings("subType"), 
  },
  {
    key: 2,
    value: "0",
    label: Strings("matricesMinimart"),
  },
  {
    key: 1,
    value: "1",
    label: Strings("specialProspect"),
  },
]