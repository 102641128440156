import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Strings } from "../../resources/i18n/i18n";
import { NumericFormat } from "react-number-format";
import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import { colors } from "../../resources/styles/themes";
import { DISCOUNT_FIELDS } from "./Constants";
import Utility from "../../utils/Utility";
import CustomNotesModal from "../CustomUIComponents/CustomNotesModal/CustomNotesModal";
import UserModal from "../CustomUIComponents/UserModal/UserModal";
import { USERS_WITH_ACCESS_APPLY_DISCOUNT } from "../../constants/appConstants";
class ApplyDiscount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loanDiscount: "",
            finalLoanDiscount: "",
            applyDiscountNote: "",
            revertDiscountNote: "",
            showRevertDiscountModal: false,
            selectedDiscountDetails: {},
            invalidFields: [],
            applyDiscountModal: false,
        };
    }
    componentDidMount() {
        this.props.getDiscountHistory();
    }
    onApplyDiscountDataChange = (event) => {
        this.setState({
            loanDiscount: event.target.value,
            errorMessage: "",
            invalidFields: this.state.invalidFields.filter((field) => field !== "loanDiscount"),
        })
    }
    renderInformation = () => {
        return (
            <Row className="pb-2">
                <Col md={12}>
                    <Row className='pt-3 underlineText d-flex align-items-center ps-0 pb-2'>
                        {Strings("Info")}
                    </Row>
                </Col>
                <Col md={4}>
                    <span className="titleText">{Strings("emi")}&nbsp;:&nbsp;</span>
                    <span className="detailText">{Utility.getCurrencyRepresentationOfAmount(this.props.loanDetails?.info.emi)}</span>
                </Col>
                <Col md={4}>
                    <span className="titleText">{Strings("uc") + (" ($)")}&nbsp;:&nbsp;</span>
                    <span className="detailText">{Utility.getCurrencyRepresentationOfAmount(this.props.loanDetails?.info.uc)}</span>
                </Col>
                <Col md={4}>
                    <span className="titleText">{Strings("di") + (" ($)")}&nbsp;:&nbsp;</span>
                    <span className="detailText">{Utility.getCurrencyRepresentationOfAmount(this.props.loanDetails?.state.di)}</span>
                </Col>
            </Row>
        );
    }
    renderApplyDiscount = () => {
        let errorClass = this.state?.invalidFields?.includes("loanDiscount") ? " error-data" : "";
        return (
            <>
                <Row className="">
                    <Col md={12}>
                        {this.state.errorMessage && (
                            <Row className='errors p-0'>
                                {Strings(this.state.errorMessage)}
                            </Row>
                        )}
                        <Row className='underlineText d-flex align-items-center ps-0 pb-2'>
                            {Strings("ApplyDiscount") + " (%)"}
                        </Row>
                    </Col>
                    <Col md={12} className="pb-2">
                        <Row className="pb-2">
                            <Col md={4} className="p-0">
                                <NumericFormat
                                    className={`form-control MuiInputBase-input MuiInput-input css-1juu494 ${errorClass}`}
                                    name={"loanDiscount"}
                                    value={this.state.loanDiscount}
                                    onValueChange={(event) => {
                                        let target = {
                                            name: "loanDiscount",
                                            value: event.value,
                                        }
                                        this.onApplyDiscountDataChange({ target })
                                    }
                                    }
                                    placeholder={Strings("Introduzca el porcentaje de descuento")}
                                    decimalScale='2'
                                    disabled={this.props.readOnly}
                                    isAllowed={({ floatValue, value }) =>
                                        // Allow empty string or validate if value is between 0 and 100
                                        value === "" || (floatValue <= 100.0 && floatValue >= 0)
                                    }
                                    isNumericString={true}
                                />
                            </Col>
                            <Col md={1}>
                                <Button
                                    className='buttonBackground buttonMinWidth'
                                    onClick={() => {
                                        this.setState({
                                            finalLoanDiscount: this.state.loanDiscount
                                        }, () => {
                                            this.props.calculateDiscount(this.state.finalLoanDiscount, this.state.applyDiscountNote)
                                        });
                                    }}
                                    disabled={
                                        this.state.loanDiscount.length < 1
                                    }
                                >
                                    {Strings("calculate")}
                                </Button>
                            </Col>
                            <Col className="ps-4">
                                <span className="titleText" style={{ whiteSpace: "wrap" }}>{Strings("loanInfoForDiscount")}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pb-4 pt-2">
                    {this.renderDiscountedValues()}
                </Row>
            </>
        );
    }
    renderDiscountedValues = () => {
        const { calculatedDiscountDetails } = this.props;
        let finalDiscountString = this.state.finalLoanDiscount ? this.state.finalLoanDiscount + "%" : "";
        return (
            <>
                <Col md={12} className="pb-2 underlineText">
                    <span>{Strings("afterCalculatingDiscount").replace("$", finalDiscountString)}</span>
                </Col>
                {DISCOUNT_FIELDS.map((field) => {
                    const value = calculatedDiscountDetails[field];
                    return (
                        <Col md={4} key={field}>
                            <span className="w-auto titleText">{Strings(field)}&nbsp;:&nbsp;</span>
                            <span className="w-auto detailText">{value !== undefined ? Utility.getCurrencyRepresentationOfAmount(value) : "-"}</span>
                        </Col>
                    );
                })}
                {calculatedDiscountDetails &&
                    <Col md={12} className="warningText pt-2" style={{ fontSize: "1em" }}>
                        {Strings("CloseLoanNote")} {Strings("discountEmiRounding")}
                    </Col>}
            </>
        );
    }
    renderTableData = (row, key) => {
        const revertString = row.isReverted ? Strings("Revert").toLocaleLowerCase() : null
        switch (key) {
            case 'pb-rl':
            case "po-act":
                return (
                    <span>
                        {row[key]
                            ? row[key].split(' \n ').map((item, idx) => (
                                <React.Fragment key={`${row.id}-${idx}`}>
                                    {item}
                                    {idx < row[key].split(' \n ').length - 1 && <br />}
                                </React.Fragment>
                            ))
                            : Strings("NA")}
                    </span>
                );

            case "dt":
                return (
                    <div>
                        <div>
                            <span style={{ fontSize: "0.9em" }}>
                                {Strings("discount")} (%)&nbsp;:&nbsp;
                            </span>
                            <span className="detailedlHeading">
                                {row[key].discount}
                            </span>
                        </div>


                        <div>
                            <span style={{ fontSize: "0.9em" }}>
                                {Strings("emiBeforeDiscount")} {revertString} ($)&nbsp;:&nbsp;
                            </span>
                            <span className="detailedlHeading">
                                {Utility.getCurrencyRepresentationOfAmount(row[key].emiBeforeDiscount)}
                            </span>
                        </div>

                        <div>
                            <span style={{ fontSize: "0.9em" }}>
                                {Strings("emiAfterDiscount")} {revertString} ($)&nbsp;:&nbsp;
                            </span>
                            <span className="detailedlHeading">
                                {Utility.getCurrencyRepresentationOfAmount(row[key].emiAfterDiscount)}
                            </span>
                        </div>
                        <div>
                            <span style={{ fontSize: "0.9em" }}>
                                {Strings("emiInterestBeforeDiscount")} {revertString} ($)&nbsp;:&nbsp;
                            </span>
                            <span className="detailedlHeading">
                                {Utility.getCurrencyRepresentationOfAmount(row[key].emiInterestBeforeDiscount)}
                            </span>
                        </div>

                        <div>
                            <span style={{ fontSize: "0.9em" }}>
                                {Strings("emiInterestAfterDiscount")} {revertString} ($)&nbsp;:&nbsp;
                            </span>
                            <span className="detailedlHeading">
                                {Utility.getCurrencyRepresentationOfAmount(row[key].emiInterestAfterDiscount)}
                            </span>
                        </div>

                        <div>
                            <span style={{ fontSize: "0.9em" }}>
                                {Strings("balanceToDateBeforeDiscount")} {revertString} ($)&nbsp;:&nbsp;
                            </span>
                            <span className="detailedlHeading">
                                {Utility.getCurrencyRepresentationOfAmount(row[key].balanceToDateBeforeDiscount)}
                            </span>
                        </div>
                        <div>
                            <span style={{ fontSize: "0.9em" }}>
                                {Strings("balanceToDateAfterDiscount")} {revertString} ($)&nbsp;:&nbsp;
                            </span>
                            <span className="detailedlHeading">
                                {Utility.getCurrencyRepresentationOfAmount(row[key].balanceToDateAfterDiscount)}
                            </span>
                        </div>

                        <div>
                            <span style={{ fontSize: "0.9em" }}>
                                {Strings("interestBeforeDiscount")} {revertString} ($)&nbsp;:&nbsp;
                            </span>
                            <span className="detailedlHeading">
                                {Utility.getCurrencyRepresentationOfAmount(row[key].interestBeforeDiscount)}
                            </span>
                        </div>

                        <div>
                            <span style={{ fontSize: "0.9em" }}>
                                {Strings("interestAfterDiscount")} {revertString} ($)&nbsp;:&nbsp;
                            </span>
                            <span className="detailedlHeading">
                                {Utility.getCurrencyRepresentationOfAmount(row[key].interestAfterDiscount)}
                            </span>
                        </div>
                    </div>


                );

            case "idr":
                return (
                    <>
                        {row[key] === true ? <span onClick={() => {
                            this.setState({
                                selectedDiscountDetails: row["dt"],
                                showRevertDiscountModal: true,
                                adid: row["id"],
                            })
                        }} className="text-center url cursorPointer textColor">{Strings("Revert")}</span> : null}
                    </>
                )
            default:
                return row[key] ? Strings(row[key]) : Strings("NA");
        }
    }

    renderCustomDataGrid = (columns, rows, callBack) => {

        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme.palette.divider,
                color: theme.palette.common.black,
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14
            }
        }))

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.action.hover,
            },
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        }))

        return (
            <div className='d-flex justify-content-center mx-3 mb-3'>
                <TableContainer
                    component={Paper}
                    className='d-flex justify-content-center'
                >
                    <Table aria-label='customized table'>
                        <TableHead>
                            <TableRow>
                                {columns.length > 0 &&
                                    columns.map((column) => (
                                        <StyledTableCell
                                            key={column.key}
                                            className={"header px-2"}
                                            title={column.name}
                                        >
                                            <div>{column.name}</div>
                                        </StyledTableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, rowIndex) => (
                                <StyledTableRow
                                    key={rowIndex}
                                    hover
                                    style={{ cursor: "pointer" }}
                                >
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            key={column.key}
                                            className={"px-2 align-baseline"}
                                            component='th'
                                            scope='row'
                                            title={column.key === "dt" ? Strings("DiscountDetails") : row[column.key]}
                                            style={{ maxWidth: column.maxWidth, minWidth: column.minWidth }}
                                        >
                                            <div
                                                className={"text-start text-break"}
                                                style={{ color: colors.textColor20, fontSize: 17, fontFamily: "Roboto-Regular" }}
                                            >
                                                {this.renderTableData(row, column.key)}
                                            </div>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };
    onChangeApplyDiscountNoteInput = (event) => {
        this.setState({
            applyDiscountNote: event.target.value,
            errorMessage: "",
            invalidFields: this.state.invalidFields.filter((field) => field !== "applyDiscountNote"),
        })
    }
    onChangeRevertDiscountNoteInput = (event) => {
        this.setState({
            revertDiscountNote: event.target.value,
            errorMessage: "",
        })
    }

    renderNoteInput = () => {
        let errorClass = this.state?.invalidFields?.includes("applyDiscountNote") ? " error-data" : "";
        return (
            <Row className=''>
                <Col md={4} className='loanNoteInputField pe-0'>
                    <textarea
                        placeholder={Strings("NoteForBlackListingCedula")}
                        name="applyDiscountNote"
                        className={`topMargin form-control noteTextArea ${errorClass}`}
                        onChange={this.onChangeApplyDiscountNoteInput}
                        value={this.props.note}
                        cols={100}
                        rows={3}
                        maxLength={500}
                    />
                </Col>
            </Row>
        );
    }
    validateApplyDiscountDetails = () => {
        let result = true;
        const invalidFields = [];
        if (!this.props.calculatedDiscountDetails) {
            result = false;
            this.setState({
                errorMessage: Strings("errorDiscountNotCalculated")
            });
        }
        else if (this.props.calculatedDiscountDetails && this.props.calculatedDiscountDetails.ne === this.props.loanDetails?.info.emi) {
            result = false;
            this.setState({
                errorMessage: Strings("PleaseTryWithOtherDiscount")
            });
        }
        else if (this.state.applyDiscountNote.trim().length < 1) {
            result = false;
            this.setState({
                errorMessage: Strings("errorEmptyNote")
            });
            invalidFields.push("applyDiscountNote");
        }
        else if (!Utility.isNoteValid(this.state.applyDiscountNote)) {
            result = false;
            this.setState({
                errorMessage: Strings("invalidNote")
            });
            invalidFields.push("applyDiscountNote");
        }
        this.setState({ invalidFields });
        return result;
    }

    renderApplyDiscountButtons = () => {
        return (
            <Row className='pb-3 pt-3'>
                <Col md={2}>
                    <Button
                        className={
                            'addOrUpdateUserTopButtons w-100 btn btn-primary'
                        }
                        onClick={() => {
                            this.validateApplyDiscountDetails() &&
                                this.setState({
                                    showUserModal: true,
                                    applyDiscountModal: true,
                                })
                        }}
                        disabled={this.props.disabled}
                    >
                        {Strings("ApplyDiscount")}
                    </Button>
                </Col>
            </Row>
        )
    }
    renderDiscountInfoNote = () => {
        return (
            <Row>
                <Col md={12}>
                    <Row>
                        <div className="w-auto ps-0 pe-1">
                            <Row>
                                <span className="informationText p-0">{Strings("CloseLoanNote")}</span>
                            </Row>
                        </div>
                        <Col md={11} className="p-0">
                            <Row >
                                <span className="informationText p-0">{Strings("discountRevertInfo")}</span>
                                <span className="informationText ps-0">{Strings("1. ") + Strings("eliminateArrears")}</span>
                                <span className="informationText ps-0">{Strings("2. ") + Strings("makePayment")}</span>

                            </Row>
                            <Row>
                                <span className="informationText ps-0 pt-1">{Strings("paymentCannotBeReverted")}</span>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
    toggleRevertDiscountModal = () => {
        this.setState({
            showRevertDiscountModal: !this.state.showRevertDiscountModal,
            applyDiscountModal: false,
        })
    }
    closeUserModal = () => {
        this.setState({
            showUserModal: false,
        })
    }
    onClickApplyDiscount = () => {
        this.setState({
            applyDiscountModal: false,
        });
        this.props.applyDiscount(this.state.finalLoanDiscount, this.state.applyDiscountNote);
        this.closeUserModal();
    }
    revertDiscount = (note) => {
        this.setState({
            revertDiscountNote: note,
            showRevertDiscountModal: !this.state.showRevertDiscountModal,
            showUserModal: true,
        })
    }
    revertDiscountConfirmationClick = () => {
        this.setState({
            showUserModal: false
        });
        this.props.revertDiscount(this.state.adid, this.state.revertDiscountNote);
    }
    renderRevertDiscountModal = () => {
        return (
            <CustomNotesModal
                open={this.state.showRevertDiscountModal}
                modalHeader={Strings("revertDiscount")}
                closeModal={this.toggleRevertDiscountModal}
                loanDiscount={this.state.loanDiscount}
                note={this.state.revertDiscountNote}
                onChange={this.onChangeRevertDiscountNoteInput}
                loanDetails={this.props.loanDetails}
                modalButtonOk={{
                    text: Strings("revertDiscount").toUpperCase(),
                    onClick: this.revertDiscount,
                }}
                modalButtonCancel={{
                    text: Strings("Cancel"),
                    onClick: this.toggleRevertDiscountModal,
                }}
                errorMessage={this.state.errorMessage}
                selectedDiscountDetails={this.state.selectedDiscountDetails}
            />
        )
    }
    renderConfirmationModalforApplyDiscount = () => {
        return (
            <UserModal
                open={this.state.showUserModal}
                modalCloseOnEsc={false}
                modalCloseOnOverlayClick={false}
                modalShowCloseIcon={false}
                headerText={
                    this.state.applyDiscountModal
                        ? Strings("ApplyDiscount")
                        : Strings("revertDiscount")
                }
                modalText1={
                    this.state.applyDiscountModal
                        ? Strings("applyDiscountConfirmation")
                        : Strings("revertDiscountConfirmation")
                }
                modalButtonOk={{
                    text: Strings("Yes"),
                    onClick: this.state.applyDiscountModal
                        ? this.onClickApplyDiscount
                        : this.revertDiscountConfirmationClick
                }}
                modalButtonCancel={{
                    text: Strings("No"),
                    onClick: this.closeUserModal
                }}
                showHr={true}
            />
        )
    }

    render() {
        return (
            <div>
                {this.state.showRevertDiscountModal && this.renderRevertDiscountModal()}
                {this.state.showUserModal && this.renderConfirmationModalforApplyDiscount()}
                <Row className='heading'>
                    {Strings("manualDiscount")}
                </Row>
                <div className='loanDetails container-fluid pb-2'>
                    <Row>
                        {this.props.ErrorMessageFromResponse &&
                            <Col md={12}>
                                <Row className='errors p-0 mb-1'>
                                    {Strings(this.props.ErrorMessageFromResponse)}
                                </Row>
                            </Col>}
                        {USERS_WITH_ACCESS_APPLY_DISCOUNT.includes(this.props.uerRole) && <>
                            <Col md={12} className="pb-3">
                                <span className='subHeading bold '>
                                    {Strings("loan")} &nbsp;-&nbsp;
                                </span>
                                <span
                                    className='detailedlHeading arrowPointer'
                                    title={Strings("loanId")}
                                >
                                    {this.props.loanDetails?.info["id"]}
                                </span>
                            </Col>
                            <Col md={11}>
                                {this.props.loanDetails && this.renderInformation()}
                                {this.renderDiscountInfoNote()}
                                <hr className="opacity-100 mt-3 mb-3" />
                                {this.renderApplyDiscount()}
                                {this.renderNoteInput()}
                                {this.renderApplyDiscountButtons()}
                            </Col>

                            <hr className="opacity-100 mt-3 mb-3" />
                        </>}
                        <Col md={12} className="pb-2">
                            <span className='subHeading bold'>
                                {Strings("manualDiscountHistory")}
                            </span>
                        </Col>
                        <Col md={12}>
                            {this.props.rows.length > 0 ?
                                this.renderCustomDataGrid(
                                    this.props.cols,
                                    this.props.rows,
                                )
                                : (
                                    <div className='noRecordsFound'>
                                        {Strings("NoRecordsFound")}
                                    </div>
                                )
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
export default ApplyDiscount;