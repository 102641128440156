import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";

export const calculateDiscountOnLoan = (
    requestObject,
    callback
  ) => {
    let requestString =
    requestObject.lid +
    "&d="+ requestObject.d
    callAPI(
      "calculateDiscountOnLoan",
      API.calculateDiscount.concat(requestString),
      "GET",
      {},
      (apiResponse) => {
        callback(apiResponse);
      }
    );
  };

  export const applyDiscountOnLoan = (
    requestObject,
    callback
  ) => {
    callAPI(
      "applyDiscountOnLoan",
      API.applyDiscount,
      "POST",
      requestObject,
      (apiResponse) => {
        callback(apiResponse);
      }
    );
  }

  export const getDiscountHistory = (
    requestObject,
    callback
  ) => {
    let requestString =
    requestObject.lid
    callAPI(
      "getDiscountHistory",
      API.getDiscountHistory.concat(requestString),
      "GET",
      {},
      (apiResponse) => {
        callback(apiResponse);
      }
    );
  }
  export const revertDiscountAction = (
    requestObject,
    callback
  ) => {
    callAPI(
      "revertDiscount",
      API.revertDiscount,
      "POST",
      requestObject,
      (apiResponse) => {
        callback(apiResponse);
      }
    );
  }