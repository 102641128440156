import { callAPI } from "../config/AxiosTemplate";
import { API } from "../constants/APIConstants";
import {
  SET_SEARCH_FILTER_ACTION,
  SET_ROW_CLICK,
} from "../redux/constants";

import { DATE_KEYS } from "../components/GeneralReports/Constants";

//Utility
import Utility from "../utils/Utility";

/**
 * Method to call api of download reports
 * @param {*} downloadUrl
 * @param {*} requestData
 * @param {*} callback
 * @param {*} type
 */
export const dowloadedAction = (
  downloadUrl,
  requestData,
  callback,
  type,
  isDownloadFile = true
) => {
  // NOSONAR
  let requestUrl = "";
  if (type === "Reports") {
    if (requestData.hasOwnProperty("search")) {
      let requestKeys = Object.keys(requestData.search);
      requestKeys.forEach((key) => {
        requestData.search[key] =
          DATE_KEYS.includes(key) &&
            requestData.search[key] !== undefined
            ? Utility.convertDateIntoMiliSeconds(
              requestData.search[key]
            )
            : requestData.search[key];
      });
    } else {
      let requestKeys = Object.keys(requestData);
      requestKeys.forEach((key) => {
        requestData[key] =
          DATE_KEYS.includes(key) && requestData[key] !== undefined
            ? Utility.convertDateIntoMiliSeconds(requestData[key])
            : requestData[key];
      });
    }
    requestUrl = API.getReports.concat(
      downloadUrl + requestData.search.file
    );
  } else if (type === "CallNotesReports") {
    requestUrl = API.exportNotes;
  } else if(type === "complaintReports"){
    requestUrl = API.downloadComplaint
  } else {
    requestUrl = API.getReports.concat(downloadUrl);
  }

  callAPI(
    "getReportsDowloadedAction",
    requestUrl,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    },
    isDownloadFile
  );
};

export const setSearchFilterAction =
  (searchFilterType, searchFilter, searchPage = "") =>
    (dispatch) => {
      dispatch({
        type: SET_SEARCH_FILTER_ACTION[searchFilterType],
        searchFilter: searchFilter,
        searchPage: searchPage,
      });
    };

export const setRowClickAction =
  (loan, searchPage = "") =>
    (dispatch) => {
      dispatch({
        type: SET_ROW_CLICK,
        loan: loan,
        searchPage: searchPage,
      });
    };

export const getAllUserListAction = (request, callback) => {
  callAPI(
    "getAllUserList",
    API.getAllUserList,
    "GET",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getFileDataAction = (request, callback) => {
  console.log('request: ', request);
  callAPI(
    "getFileData",
    API.getFileData + request,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
}
