import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Modal } from "react-responsive-modal";
import { Strings } from "../../../resources/i18n/i18n";
import Utility from "../../../utils/Utility";
import "./CustomNotesModal.scss";
class CustomNotesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            note: "",
        };
    }
    onChangeNoteInput = (event) => {
        this.setState({
            note: event.target.value,
            errorMessage: "",
        })
    }
    validateAndSubmitInput = () => {
        if (this.state.note.trim().length < 1 ) {
            this.setState({
                errorMessage: Strings("errorEmptyNote")
            });
        }else if (!Utility.isNoteValid(this.state.note)){
            this.setState({
                errorMessage: Strings("invalidNote")
            });
        }else{
            this.props.modalButtonOk.onClick(this.state.note);
        }
    
    }

    renderModalButtons = () => {
        return (
            <Row className='customNotesModalButton'>
                <Col md={12}>
                    {this.props.modalButtonOk && (
                        <Button
                            className={
                                'buttonBackground buttonMinWidth'
                            }
                            onClick={() => this.validateAndSubmitInput()}
                            disabled={this.props.disabled}
                        >
                            {this.props.modalButtonOk.text}
                        </Button>
                    )}
                    {this.props.modalButtonCancel && <Button
                        className={
                            " primaryModalButton buttonMinWidth marginCancelButton buttonBorder"
                        }
                        onClick={() => this.props.modalButtonCancel.onClick()}
                    >
                        {this.props.modalButtonCancel.text}
                    </Button>}
                </Col>
            </Row>
        )
    }
    showNotes = () => {
        return (
            <Row className='p-2'>
                <div className='loanNoteInputField p-0 d-flex justify-center'>
                    <textarea
                        placeholder={Strings("NoteForBlackListingCedula")}
                        className='topMargin form-control noteTextArea w-50'
                        onChange={this.onChangeNoteInput}
                        value={this.state.note}
                        cols={100}
                        rows={3}
                        maxLength={500}
                    />
                </div>
            </Row>
        );
    }
    showRevertDiscount = () => {
        const selectedDiscountDetails = this.props.selectedDiscountDetails
        return (
            <div className="pb-4">
                <Row>
                    <Col md={12}>
                        <span className="subHeading bold">
                            {Strings("currentlyAppliedDiscountInfo")}
                        </span>
                    </Col>
                    <Col md={4}>
                        <span className="titleText">{Strings("discount") + " (%)"}&nbsp;:&nbsp;</span>
                        <span className="detailText">{selectedDiscountDetails.discount}</span>
                    </Col>
                    <Col md={4}>
                        <span className="titleText">{Strings("interest") + " ($)"}&nbsp;:&nbsp;</span>
                        <span className="detailText">{Utility.getCurrencyRepresentationOfAmount(selectedDiscountDetails.interestAfterDiscount)}</span>
                    </Col>
                    <Col md={4}>
                        <span className="titleText">{Strings("emi")}&nbsp;:&nbsp;</span>
                        <span className="detailText">{Utility.getCurrencyRepresentationOfAmount(selectedDiscountDetails.emiAfterDiscount)}</span>
                    </Col>

                </Row>
                <hr className="opacity-100 mt-3" />
                <Row>
                    <Col md={12}>
                        <span className="subHeading bold">
                            {Strings("afterRevertingDiscountInfo")}
                        </span>
                    </Col>
                    <Col md={4}>
                        <span className="titleText">{Strings("interest") + " ($)"}&nbsp;:&nbsp;</span>
                        <span className="detailText">{Utility.getCurrencyRepresentationOfAmount(selectedDiscountDetails.interestBeforeDiscount)}</span>
                    </Col>
                    <Col md={4}>
                        <span className="titleText">{Strings("emi")}&nbsp;:&nbsp;</span>
                        <span className="detailText">{Utility.getCurrencyRepresentationOfAmount(selectedDiscountDetails.emiBeforeDiscount)}</span>
                    </Col>
                </Row>
            </div>
        );
    }
    render() {
        return (
            <div className='notesModal'>
                <Modal
                    open={this.props.open}
                    onClose={this.props.closeModal}
                    classNames={{ modal: "customNoteModal" }}
                    closeOnEsc={false}
                    closeOnOverlayClick={false}
                    showCloseIcon={false}
                    center
                >
                    <Row className='notesModal'>
                        <Col md={12} className='headerModal ps-3'>
                            {this.props.modalHeader}
                        </Col>
                        <Col md={12} className="modalContent p-4">
                        {this.state.errorMessage && (
                                <Row className='errors mb-3' style={{paddingLeft:"0.8em"}}>
                                    {Strings(this.state.errorMessage)}
                                </Row>
                            )}
                            {this.props.selectedDiscountDetails && this.showRevertDiscount()}
                            {this.showNotes()}
                            {this.renderModalButtons()}
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}
export default CustomNotesModal;