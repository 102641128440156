import { Strings } from "../../resources/i18n/i18n";

import { ROUTES } from "../../constants/routeConstants";

import DashBoards from "../../resources/images/ic_dashboards.png";
import Work from "../../resources/images/ic_work.png";
import MTP from "../../resources/images/ic_mtp.png";
import CI from "../../resources/images/ic_ci.png";
import Loans from "../../resources/images/ic_loans.png";
import Management from "../../resources/images/ic_management.png";
import Catalog from "../../resources/images/ic_catalog.png";

import UserManagementIcon from "../../resources/images/ic_user_management.png";
import BranchManagementIcon from "../../resources/images/ic_branch_management.png";
import LoanPortIcon from "../../resources/images/ic_loan_portfolio_management.png";
import MinimartManagement from "../../resources/images/ic_mini_market.png";
import Reports from "../../resources/images/ic_reports.png";
import DailyStatus from "../../resources/images/ic_daily_status.png";
import Dashboard from "../../resources/images/ic_dashboard.png";
import CIInterface from "../../resources/images/ic_ci_interface.png";
import CILoanIcon from "../../resources/images/Ic_Ci_Menu_Option.svg";
import EarlyPayment from "../../resources/images/ic_early_payment.png";
import LoanStatus from "../../resources/images/ic_loan_status.png";
import EarlyCancellation from "../../resources/images/ic_early_cancellation.png";
import MinimartGrade from "../../resources/images/ic_grades.png";
import LateFee from "../../resources/images/ic_late_fees.png";
import LoanPickup from "../../resources/images/ic_loan_pickup.png";
import GracePeriod from "../../resources/images/ic_grace_period.png";
import CommissionScheme from "../../resources/images/ic_commission_scheme.png";
import Provision from "../../resources/images/ic_provision.png";
import WORKLOAD_MANAGEMENT from "../../resources/images/ic_workload_management.png";
import WORK_HISTORY from "../../resources/images/ic_work_history_screen.png";
import CALL_NOTES from "../../resources/images/ic_call_notes.png";
import EMI_ICON from "../../resources/images/ic_emi.png";
import MAP_SCREEN from "../../resources/images/ic_map_screen.png";
import CollectionVerification from "../../resources/images/ic_collection_verification.png";
import ApprovalDashboard from "../../resources/images/ic_approval.png";
import Scheduler from "../../resources/images/ic_scheduler_configuration.png";
import CreditScore from "../../resources/images/ic_credit_score.png";
import AnalystApproval from "../../resources/images/ic_analyst_loan_approval.png";
import LoanHistory from "../../resources/images/ic_loan_history.png";
import ProductCatalog from "../../resources/images/ic_product_catalogue.png";
import CIWire from "../../resources/images/ic_ci_wire_transfer.png";
import Holiday from "../../resources/images/ic_holiday_list.png";
import MinimartHistory from "../../resources/images/ic_approval_history.png";
import MinimartApproval from "../../resources/images/ic_mini_market_approval.png";
import SPED from "../../resources/images/ic_sped.png";
import MotorcycleCatalogue from "../../resources/images/ic_motorcycle_catalogue.png";
import MTPComplaint from "../../resources/images/ic_mtp_complaint.png";
import MTPDashboard from "../../resources/images/ic_mtp_dashboard.png";
import MTPLadder from "../../resources/images/ic_mtp_ladder.png";
import MTPProducts from "../../resources/images/ic_mtp_products.png";
import MTPPromotions from "../../resources/images/ic_mtp_promotions.png";
import MTPLoanRequests from "../../resources/images/loanRequests.png";
import RemittancePayment from "../../resources/images/ic_remittance_payment.png";
import NewMinimartManagement from "../../resources/images/new_minimart_management.png";
import PAYMENT_DISCOUNT from "../../resources/images/ic_payment_discount.png";
import ZonePositionMappingImg from "../../resources/images/zone_position_mapping.png";
import MTPMinimartRequests from "../../resources/images/minimartRequests.png";
import SIGN_UP_REQUEST from "../../resources/images/signup_request.png";
import AUTO_ANALYSIS from "../../resources/images/analytic_configurator.png";
import NewCreditScore from "../../resources/images/ic_configure_credit_score.png";
import PromotionApproval from "../../resources/images/promotion_approval.png";
import RewardCatalog from "../../resources/images/reward_catalog.png";
import RewardConfigurator from "../../resources/images/reward_configurator.png";
import RewardApproval from "../../resources/images/reward_redeem_request.png";
import InvoiceApproval from "../../resources/images/invoice_approval.svg";
import ClientPortalIcon from "../../resources/images/ic_client_portal.svg";
import MetaMapIcon from "../../resources/images/ic_metamap.svg";
import BankAccountConfiguration from "../../resources/images/ic_bank_account_type.svg";
import SupplierDetails from "../../resources/images/ic_supllier_details.svg";
import AdministrativeExpense from "../../resources/images/ic_administrative_expenses.svg";
import PAYMENT_FREQ_ICON from "../../resources/images/ic_payment_frequencies.svg"

import CREDO_CONFIG_ICON from "../../resources/images/ic_credo_score.svg";
function SideBarMenuItems() {
  /* FORMAT:
    DASHBOARD: {                  -> feature name as received from server
      key: 1,                       -> object key
      to: ROUTES.DASHBOARD,         -> click redirects to this path
      title: Strings("Dashboard"),  -> text displayed on hovering over sidebar menu icon
      icon: Dashboard,              -> icon
      activeIcon: Dashboard,        -> icon for when the feature is selected/active, currently active/inactive icons are same
      urls: [ROUTES.DASHBOARD],     -> urls for which the icon should be in active state
    }
  */
  return {
    DASHBOARD: {
      key: 1,
      featureName: "DASHBOARD",
      to: ROUTES.DASHBOARD,
      title: Strings("Dashboard"),
      icon: Dashboard,
      activeIcon: Dashboard,
      isActive: false,
      urls: [ROUTES.DASHBOARD],
    },
    APPROVAL_DASHBOARD: {
      key: 2,
      featureName: "APPROVAL_DASHBOARD",
      to: ROUTES.APPROVAL_DASHBOARD,
      title: Strings("ApprovalDashboard"),
      icon: ApprovalDashboard,
      activeIcon: ApprovalDashboard,
      isActive: false,
      urls: [
        ROUTES.ASSIGN_REPAYMENT,
        ROUTES.ELIGIBILITY_CRITERIA,
        ROUTES.MINIMART_DETAILS,
        ROUTES.LOAN_APPLICATION_DETAILS,
        ROUTES.VIEW_LOAN_APPLICATIONS,
        ROUTES.APPLY_DISCOUNT,
        ROUTES.NOTE_DETAILS,
        ROUTES.APPROVAL_DASHBOARD,
      ],
    },
    DAILY_STATUS: {
      key: 3,
      featureName: "DAILY_STATUS",
      to: ROUTES.DAILY_STATUS,
      title: Strings("DailyStatus"),
      icon: DailyStatus,
      activeIcon: DailyStatus,
      isActive: false,
      urls: [
        ROUTES.DAILY_STATUS_MESSENGER,
        ROUTES.DAILY_STATUS_MODERATOR,
        ROUTES.SALES_PERSON_DAILY_STATUS_DETAILS,
        ROUTES.MESSENGER_DAILY_STATUS_DETAILS,
        ROUTES.MINIMART_DETAILS,
        ROUTES.LOAN_APPLICATION_DETAILS,
        ROUTES.VIEW_LOAN_APPLICATIONS,
        ROUTES.APPLY_DISCOUNT,
        ROUTES.NOTE_DETAILS,
        ROUTES.SALES_PERSON_WORK_HISTORY,
        ROUTES.MESSENGER_WORK_HISTORY,
        ROUTES.DAILY_STATUS,
      ],
    },
    LOAN_PORTFOLIO_MANAGEMENT: {
      key: 4,
      featureName: "LOAN_PORTFOLIO_MANAGEMENT",
      to: ROUTES.LOAN_PORTFOLLIO_MANAGEMENT,
      title: Strings("LoanPortFolioManagement"),
      icon: LoanPortIcon,
      activeIcon: LoanPortIcon,
      isActive: false,
      urls: [
        ROUTES.LOAN_APPLICATION_DETAILS,
        ROUTES.VIEW_LOAN_APPLICATIONS,
        ROUTES.APPLY_DISCOUNT,
        ROUTES.NOTE_DETAILS,
        ROUTES.LOAN_PORTFOLLIO_MANAGEMENT,
        ROUTES.APPLY_DISCOUNT,
        ROUTES.NOTE_DETAILS,
      ],
    },
    WORKLOAD_MANAGEMENT: {
      key: 5,
      featureName: "WORKLOAD_MANAGEMENT",
      to: ROUTES.WORKLOAD_MANAGEMENT,
      title: Strings("WorkloadManagement"),
      icon: WORKLOAD_MANAGEMENT,
      activeIcon: WORKLOAD_MANAGEMENT,
      isActive: false,
      urls: [
        ROUTES.WORKLOAD_MANAGEMENT,
        ROUTES.MINIMART_DETAILS,
        ROUTES.LOAN_APPLICATION_DETAILS,
        ROUTES.VIEW_LOAN_APPLICATIONS,
        ROUTES.APPLY_DISCOUNT,
        ROUTES.NOTE_DETAILS,
        ROUTES.MINIMART_MANAGEMENT,
      ],
    },
    MINIMART_MANAGEMENT: {
      key: 6,
      featureName: "MINIMART_MANAGEMENT",
      to: ROUTES.MINIMART_MANAGEMENT,
      title: Strings("miniMArtManagement"),
      icon: MinimartManagement,
      activeIcon: MinimartManagement,
      isActive: false,
      urls: [
        ROUTES.MINIMART_DETAILS,
        ROUTES.LOAN_APPLICATION_DETAILS,
        ROUTES.APPLY_DISCOUNT,
        ROUTES.NOTE_DETAILS,
        ROUTES.VIEW_LOAN_APPLICATIONS,
        ROUTES.MINIMART_MANAGEMENT,
      ],
    },
    NEW_MINIMART_MANAGEMENT: {
      key: 6,
      featureName: "NEW_MINIMART_MANAGEMENT",
      to: ROUTES.NEW_MINIMART_MANAGEMENT,
      title: Strings("newMinimArtManagement"),
      icon: NewMinimartManagement,
      activeIcon: NewMinimartManagement,
      isActive: false,
      urls: [
        ROUTES.MINIMART_DETAILS,
        ROUTES.LOAN_APPLICATION_DETAILS,
        ROUTES.VIEW_LOAN_APPLICATIONS,
        ROUTES.APPLY_DISCOUNT,
        ROUTES.NOTE_DETAILS,
        ROUTES.NEW_MINIMART_MANAGEMENT,
      ],
    },
    WORK_HISTORY: {
      key: 7,
      featureName: "WORK_HISTORY",
      to: ROUTES.USER_WORK_HISTORY,
      title: Strings("WorkHistory"),
      icon: WORK_HISTORY,
      activeIcon: WORK_HISTORY,
      isActive: false,
      urls: [
        ROUTES.WORK_HISTORY_MESSENGER,
        ROUTES.SALES_PERSON_WORK_HISTORY,
        ROUTES.MESSENGER_WORK_HISTORY,
        ROUTES.USER_WORK_HISTORY,
      ],
    },
    CI_INTERFACE: {
      key: 8,
      featureName: "CI_INTERFACE",
      to: ROUTES.CI_INTERFACE,
      title: Strings("CIInterface"),
      icon: CIInterface,
      activeIcon: CIInterface,
      isActive: false,
      urls: [ROUTES.FILE_CONTENT, ROUTES.CI_INTERFACE],
    },
    CI_COLLECTION_APP_LOAN_REQUEST: {
      key: 51,
      featureName: "CI_COLLECTION_APP_LOAN_REQUEST",
      to: ROUTES.CI_COLLECTION_APP_LOAN_REQUEST,
      title: Strings("CILoanRequestReport"),
      icon: CILoanIcon,
      activeIcon: CILoanIcon,
      isActive: false,
      urls: [ROUTES.CI_COLLECTION_APP_LOAN_REQUEST],
    },
    USER_MANAGEMENT: {
      key: 9,
      featureName: "USER_MANAGEMENT",
      to: ROUTES.USER_LIST,
      title: Strings("UserManagement"),
      icon: UserManagementIcon,
      activeIcon: UserManagementIcon,
      isActive: false,
      urls: [ROUTES.USER_PROFILE, ROUTES.USER_LIST],
    },
    BRANCH_MANAGEMENT: {
      key: 10,
      featureName: "BRANCH_MANAGEMENT",
      to: ROUTES.BRANCH_LIST,
      title: Strings("BranchManagement"),
      icon: BranchManagementIcon,
      activeIcon: BranchManagementIcon,
      isActive: false,
      urls: [ROUTES.BRANCH_DETAILS, ROUTES.BRANCH_LIST],
    },
    MAP: {
      key: 11,
      featureName: "MAP",
      to: ROUTES.MAP,
      title: Strings("consolidatedMap"),
      icon: MAP_SCREEN,
      activeIcon: MAP_SCREEN,
      isActive: false,
      urls: [ROUTES.MAP],
    },
    REPORTS: {
      key: 52,
      featureName: "REPORTS",
      to: ROUTES.REPORTS,
      title: Strings("Reports"),
      icon: Reports,
      activeIcon: Reports,
      isActive: false,
      urls: [
        ROUTES.LOAN_PORTFOLIO_REPORTS,
        ROUTES.REPORTS_FOR_SINGLE_LOAN_PORTFOLIO,
        ROUTES.SALES_REPORTS,
        ROUTES.REPORTS_FOR_SINGLE_SALES,
        ROUTES.COLLECTIONS_REPORTS,
        ROUTES.WEB_COLLECTIONS_REPORTS,
        ROUTES.REPORTS_FOR_SINGLE_COLLECTIONS,
        ROUTES.REPORTS_FOR_SINGLE_WEB_COLLECTIONS,
        ROUTES.LOAN_APPLICATION_DETAILS,
        ROUTES.VIEW_LOAN_APPLICATIONS,
        ROUTES.APPLY_DISCOUNT,
        ROUTES.NOTE_DETAILS,
        ROUTES.REPORTS,
        ROUTES.WALLET_REPORTS,
        ROUTES.REPORTS_FOR_SINGLE_WALLET,
        ROUTES.SPECIAL_SALES_REPORTS,
        ROUTES.REPORTS_FOR_SINGLE_SPECIAL_SALES,
      ],
    },
    CALL_NOTES: {
      key: 13,
      featureName: "CALL_NOTES",
      to: ROUTES.CALL_NOTES,
      title: Strings("callNotes"),
      icon: CALL_NOTES,
      activeIcon: CALL_NOTES,
      isActive: false,
      urls: [ROUTES.CALL_NOTES],
    },
    COLLECTION_VERIFICATION: {
      key: 14,
      featureName: "COLLECTION_VERIFICATION",
      to: ROUTES.COLLECTION_VERIFICATION,
      title: Strings("CollectionVerification"),
      icon: CollectionVerification,
      activeIcon: CollectionVerification,
      isActive: false,
      urls: [
        ROUTES.VERIFY_COLLECTION,
        ROUTES.COLLECTION_VERIFICATION,
      ],
    },
    CREDIT_SCORE: {
      key: 15,
      featureName: "CREDIT_SCORE",
      to: ROUTES.CREDIT_SCORE,
      title: Strings("CreditScore"),
      icon: CreditScore,
      activeIcon: CreditScore,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.CREDIT_SCORE],
    },
    EARLY_PAYMENT: {
      key: 16,
      featureName: "EARLY_PAYMENT",
      to: ROUTES.EARLY_PAYMENT,
      title: Strings("EarlyPayment"),
      icon: EarlyPayment,
      activeIcon: EarlyPayment,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.EARLY_PAYMENT],
    },
    EMI: {
      key: 17,
      featureName: "EMI",
      to: ROUTES.EMI,
      title: Strings("EMI"),
      icon: EMI_ICON,
      activeIcon: EMI_ICON,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.EMI],
    },
    PAYMENT_FREQUENCY: {
      key: 17,
      featureName: "PAYMENT_FREQUENCY",
      to: ROUTES.PAYMENT_FREQUENCY,
      title: Strings("PAYMENT_FREQUENCY"),
      icon: PAYMENT_FREQ_ICON,
      activeIcon: PAYMENT_FREQ_ICON,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.PAYMENT_FREQUENCY],
    },
    LOAN_STATUS_AND_GRADE: {
      key: 17,
      featureName: "LOAN_STATUS_AND_GRADE",
      to: ROUTES.LOAN_STATUS_AND_GRADE,
      title: Strings("LOAN_STATUS_AND_GRADE"),
      icon: LoanStatus,
      activeIcon: LoanStatus,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.LOAN_STATUS_AND_GRADE],
    },
    LOAN_STATUS: {
      key: 18,
      featureName: "LOAN_STATUS",
      to: ROUTES.LOAN_STATUS,
      title: Strings("LoanStatus"),
      icon: LoanStatus,
      activeIcon: LoanStatus,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.LOAN_STATUS],
    },
    EARLY_CANCELLATION: {
      key: 19,
      featureName: "EARLY_CANCELLATION",
      to: ROUTES.EARLY_CANCELLATION,
      title: Strings("EarlyCancellation"),
      icon: EarlyCancellation,
      activeIcon: EarlyCancellation,
      isActive: false,
      urls: [ROUTES.EARLY_CANCELLATION],
    },
    MINIMART_GRADE: {
      key: 20,
      featureName: "MINIMART_GRADE",
      to: ROUTES.MINIMART_GRADE,
      title: Strings("MinimartGrade"),
      icon: MinimartGrade,
      activeIcon: MinimartGrade,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.MINIMART_GRADE],
    },
    LATE_FEE: {
      key: 21,
      featureName: "LATE_FEE",
      to: ROUTES.LATE_FEE,
      title: Strings("LateFee"),
      icon: LateFee,
      activeIcon: LateFee,
      isActive: false,
      urls: [ROUTES.LATE_FEE],
    },
    LOAN_PICKUP: {
      key: 22,
      featureName: "LOAN_PICKUP",
      to: ROUTES.LOAN_PICKUP,
      title: Strings("LoanPickup"),
      icon: LoanPickup,
      activeIcon: LoanPickup,
      isActive: false,
      urls: [ROUTES.LOAN_PICKUP],
    },
    GRACE_PERIOD: {
      key: 23,
      featureName: "GRACE_PERIOD",
      to: ROUTES.GRACE_PERIOD,
      title: Strings("GracePeriod"),
      icon: GracePeriod,
      activeIcon: GracePeriod,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.GRACE_PERIOD],
    },
    COMMISSION_SCHEME: {
      key: 24,
      featureName: "COMMISSION_SCHEME",
      to: ROUTES.COMMISSION_SCHEME,
      title: Strings("CommissionScheme"),
      icon: CommissionScheme,
      activeIcon: CommissionScheme,
      isActive: false,
      urls: [
        ROUTES.COMMISSION_SCHEME,
        ROUTES.SALES_COMISSION,
        ROUTES.MESSENGER_COMISSION,
        ROUTES.SALES_MODERATOR_COMISSION,
        ROUTES.MESSENGER_MODERATOR_COMISSION,
        ROUTES.COMMISSION_REPORT,
        ROUTES.DETAIL_COMMISSION_REPORT,
      ],
    },
    PROVIOSION: {
      key: 25,
      featureName: "PROVIOSION",
      to: ROUTES.PROVIOSION,
      title: Strings("Provision"),
      icon: Provision,
      activeIcon: Provision,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.PROVIOSION],
    },
    SCHEDULER: {
      key: 26,
      featureName: "SCHEDULER",
      to: ROUTES.CIDATA_SCHEDULER,
      title: Strings("CIDATA_SCHEDULER"),
      icon: Scheduler,
      activeIcon: Scheduler,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.CIDATA_SCHEDULER],
    },
    ANALYST_DASHBOARD: {
      key: 27,
      featureName: "ANALYST_DASHBOARD",
      to: ROUTES.LOAN_APPROVAL_DASHBOARD,
      title: Strings("AnalystApprovalDashboard"),
      icon: AnalystApproval,
      activeIcon: AnalystApproval,
      isActive: false,
      urls: [
        ROUTES.ANALYST_LOAN_VIEW,
        ROUTES.LOAN_APPLICATION_DETAILS,
        ROUTES.VIEW_LOAN_APPLICATIONS,
        ROUTES.APPLY_DISCOUNT,
        ROUTES.NOTE_DETAILS,
        ROUTES.LOAN_APPROVAL_DASHBOARD,
      ],
    },
    OPERATIONS_OFFICER_DASHBOARD: {
      key: 28,
      featureName: "OPERATIONS_OFFICER_DASHBOARD",
      to: ROUTES.OPERATION_LOAN_APPROVAL_DASHBOARD,
      title: Strings("OperationsOfficerDashboard"),
      icon: AnalystApproval,
      activeIcon: AnalystApproval,
      isActive: false,
      urls: [
        ROUTES.ANALYST_LOAN_VIEW,
        ROUTES.OPERATION_LOAN_APPROVAL_DASHBOARD,
      ],
    },
    ACCOUNTING_OFFICER_DASHBOARD: {
      key: 29,
      featureName: "ACCOUNTING_OFFICER_DASHBOARD",
      to: ROUTES.LOAN_APPROVAL_DASHBOARD,
      title: Strings("AccountingOfficerDashboard"),
      icon: AnalystApproval,
      activeIcon: AnalystApproval,
      isActive: false,
      urls: [
        ROUTES.ANALYST_LOAN_VIEW,
        ROUTES.LOAN_APPROVAL_DASHBOARD,
      ],
    },
    PRODUCT_CATALOG: {
      key: 30,
      featureName: "PRODUCT_CATALOG",
      to: ROUTES.PRODUCT_CATALOG,
      title: Strings("ProductCatalog"),
      icon: ProductCatalog,
      activeIcon: ProductCatalog,
      isActive: false,
      urls: [ROUTES.PRODUCT_CATALOG],
    },
    LOAN_HISTORY: {
      key: 31,
      featureName: "LOAN_HISTORY",
      to: ROUTES.LOAN_HISTORY,
      title: Strings("loanHistory"),
      icon: LoanHistory,
      activeIcon: LoanHistory,
      isActive: false,
      urls: [ROUTES.ANALYST_LOAN_VIEW, ROUTES.LOAN_HISTORY],
    },
    CI_WIRE_TRANSFER: {
      key: 32,
      featureName: "CI_WIRE_TRANSFER",
      to: ROUTES.CI_WIRE_TRANSFER,
      title: Strings("CIWireTransfer"),
      icon: CIWire,
      activeIcon: CIWire,
      isActive: false,
      urls: [ROUTES.CI_WIRE_TRANSFER],
    },
    USER_COMMISSION: {
      key: 33,
      featureName: "USER_COMMISSION",
      to: ROUTES.USER_COMMISSION,
      title: Strings("CommissionScheme"),
      icon: CommissionScheme,
      activeIcon: CommissionScheme,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.USER_COMMISSION],
    },
    HOLIDAY: {
      key: 34,
      featureName: "HOLIDAY",
      to: ROUTES.HOLIDAY,
      title: Strings("Holiday"),
      icon: Holiday,
      activeIcon: Holiday,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.HOLIDAY],
    },
    MINIMART_APPROVAL_DASHBOARD: {
      key: 35,
      featureName: "MINIMART_APPROVAL_DASHBOARD",
      to: ROUTES.MINIMART_APPROVAL_REQUESTS,
      title: Strings("MinimartApprovalRequests"),
      icon: MinimartApproval,
      activeIcon: MinimartApproval,
      isActive: false,
      urls: [
        ROUTES.APPROVAL_MINIMART_DETAILS,
        ROUTES.MINIMART_APPROVAL_REQUESTS,
      ],
    },
    MINIMART_HISTORY: {
      key: 36,
      featureName: "MINIMART_HISTORY",
      to: ROUTES.APPROVAL_MINIMART_HISTORY,
      title: Strings("minimartHistory"),
      icon: MinimartHistory,
      activeIcon: MinimartHistory,
      isActive: false,
      urls: [
        ROUTES.APPROVAL_MINIMART_DETAILS,
        ROUTES.APPROVAL_MINIMART_HISTORY,
      ],
    },
    MOTORCYCLE_CATALOG: {
      //Received from server as MOTORCYCLE_CATALOG, used everywhere else as Catalog of Appliances and Equipment
      key: 37,
      featureName: "MOTORCYCLE_CATALOG",
      to: ROUTES.MOTORCYCLE_CATALOGUE,
      title: Strings("motorcycleCatalogueTitleText"),
      icon: MotorcycleCatalogue,
      activeIcon: MotorcycleCatalogue,
      isActive: false,
      urls: [ROUTES.MOTORCYCLE_CATALOGUE],
    },
    SALES_EFFICIENCY_DASHBOARD: {
      key: 38,
      featureName: "SALES_EFFICIENCY_DASHBOARD",
      to: ROUTES.SALES_EFFICIENCY_DASHBOARD,
      title: Strings("SalespersonEfficiencyDashboard"),
      icon: SPED,
      activeIcon: SPED,
      isActive: false,
      urls: [
        ROUTES.SINGLE_SALES_PERSON,
        ROUTES.LOAN_APPLICATION_DETAILS,
        ROUTES.VIEW_LOAN_APPLICATIONS,
        ROUTES.APPLY_DISCOUNT,
        ROUTES.NOTE_DETAILS,
        ROUTES.SALES_EFFICIENCY_DASHBOARD,
      ],
    },

    //Define MTP strings
    /*MTP_APPROVAL: {
      key: 39,
      featureName: "MTP_APPROVAL",
      to: ROUTES.MTP_APPROVAL,
      title: Strings("Approval"),
      icon: MTPApproval,
      activeIcon: MTPApproval,
      isActive: false,
      urls: []
    },*/
    MTP_COMPLAINT_PORTAL: {
      key: 40,
      featureName: "MTP_COMPLAINT_PORTAL",
      to: ROUTES.MTP_COMPLAINT_PORTAL,
      title: Strings("MTPComplaintPortal"),
      icon: MTPComplaint,
      activeIcon: MTPComplaint,
      isActive: false,
      urls: [],
    },
    MTP_DASHBOARD: {
      key: 41,
      featureName: "MTP_DASHBOARD",
      to: ROUTES.MTP_DASHBOARD,
      title: Strings("MTPDashboard"),
      icon: MTPDashboard,
      activeIcon: MTPDashboard,
      isActive: false,
      urls: [],
    },
    MTP_LADDER: {
      key: 42,
      featureName: "MTP_LADDER",
      to: ROUTES.MTP_LADDER,
      title: Strings("MTPLadder"),
      icon: MTPLadder,
      activeIcon: MTPLadder,
      isActive: false,
      urls: [],
    },
    MTP_PRODUCTS: {
      key: 43,
      featureName: "MTP_PRODUCTS",
      to: ROUTES.MTP_PRODUCTS,
      title: Strings("MTPProducts"),
      icon: MTPProducts,
      activeIcon: MTPProducts,
      isActive: false,
      urls: [],
    },
    MTP_PROMOTIONS: {
      key: 44,
      featureName: "MTP_PROMOTIONS",
      to: ROUTES.MTP_PROMOTIONS,
      title: Strings("MTPPromotions"),
      icon: MTPPromotions,
      activeIcon: MTPPromotions,
      isActive: false,
      urls: [],
    },
    MTP_LOAN_REQUESTS: {
      key: 45,
      featureName: "MTP_LOAN_REQUESTS",
      to: ROUTES.MTP_LOAN_REQUESTS,
      title: Strings("MTPLoanRequests"),
      icon: MTPLoanRequests,
      activeIcon: MTPLoanRequests,
      isActive: false,
      urls: [],
    },

    MTP_MINIMART_REQUESTS: {
      key: 45,
      featureName: "MTP_MINIMART_REQUESTS",
      to: ROUTES.MTP_MINIMART_REQUESTS,
      title: Strings("MTPEditMinimrtRequests"),
      icon: MTPMinimartRequests,
      activeIcon: MTPMinimartRequests,
      isActive: false,
      urls: [],
    },
    REMITTANCE_PAYMENT: {
      key: 46,
      featureName: "REMITTANCE_PAYMENT",
      to: ROUTES.REMITTANCE_PAYMENT,
      title: Strings("REMITTANCE_PAYMENT"),
      icon: RemittancePayment,
      activeIcon: RemittancePayment,
      isActive: false,
      urls: [],
    },
    PAYMENT_DISCOUNT: {
      key: 47,
      featureName: "PAYMENT_DISCOUNT",
      to: ROUTES.PAYMENT_DISCOUNT,
      title: Strings("PAYMENT_DISCOUNT"),
      icon: PAYMENT_DISCOUNT,
      activeIcon: PAYMENT_DISCOUNT,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.PAYMENT_DISCOUNT],
    },
    ZONE_POSITION_MAPPING: {
      key: 48,
      featureName: "ZONE_POSITION_MAPPING",
      to: ROUTES.ZONE_POSITION_MAPPING,
      title: Strings("ZonePositionMapping"),
      icon: ZonePositionMappingImg,
      activeIcon: UserManagementIcon,
      isActive: false,
      urls: [ROUTES.ZONE_POSITION_MAPPING], //what will come here
    },
    MTP_SIGNUP: {
      key: 49,
      featureName: "MTP_SIGNUP",
      to: ROUTES.MTP_SIGNUP_REQUEST,
      title: Strings("MTPSignupRequests"),
      icon: SIGN_UP_REQUEST,
      activeIcon: SIGN_UP_REQUEST,
      isActive: false,
      urls: [
        ROUTES.MTP_SIGNUP_REQUEST,
        ROUTES.MTP_SIGNUP_REQUEST_DETAILS,
      ],
    },
    AUTO_ANALYSIS: {
      key: 47,
      featureName: "AUTO_ANALYSIS",
      to: ROUTES.ANALYST,
      title: Strings("ConfigurationManagement_AUTO_ANALYSIS"),
      icon: AUTO_ANALYSIS,
      activeIcon: AUTO_ANALYSIS,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.ANALYST],
    },
    PROSPECT_CREDIT_SCORE: {
      key: 15,
      featureName: "PROSPECT_CREDIT_SCORE",
      to: ROUTES.NEW_CREDIT_SCORE,
      title: Strings("NewCreditScore"),
      icon: NewCreditScore,
      activeIcon: NewCreditScore,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.NEW_CREDIT_SCORE],
    },
    MTP_PROMOTIONS_APPROVAL: {
      key: 44,
      featureName: "MTP_PROMOTIONS_APPROVAL",
      to: ROUTES.MTP_PROMOTIONS_APPROVAL,
      title: Strings("MTPPromotionsApproval"),
      icon: PromotionApproval,
      activeIcon: PromotionApproval,
      isActive: false,
      urls: [
        ROUTES.MTP_PROMOTIONS_APPROVAL,
        ROUTES.MTP_PROMOTION_DETAILS,
      ],
    },
    REWARD_CATALOG: {
      key: 45,
      featureName: "REWARD_CATALOG",
      to: ROUTES.REWARD_CATALOG,
      title: Strings("RewardCatalog"),
      icon: RewardCatalog,
      activeIcon: RewardCatalog,
      isActive: false,
      urls: [ROUTES.REWARD_CATALOG],
    },
    REWARDS: {
      key: 46,
      featureName: "REWARDS",
      to: ROUTES.REWARD_CONFIGURATION,
      title: Strings("Rewards"),
      icon: RewardConfigurator,
      activeIcon: RewardConfigurator,
      isActive: false,
      urls: [
        ROUTES.CONFIGURATION_DETAILS,
        ROUTES.REWARD_CONFIGURATION,
      ],
    },
    REWARD_REDEEM_APPROVAL: {
      key: 46,
      featureName: "REWARD_REDEEM_APPROVAL",
      to: ROUTES.REWARD_APPROVAL,
      title: Strings("rewardApprovalFeatureTitle"),
      icon: RewardApproval,
      activeIcon: RewardApproval,
      isActive: false,
      urls: [ROUTES.REWARD_APPROVAL_DETAILS, ROUTES.REWARD_APPROVAL],
    },
    INVOICE_APPROVAL: {
      key: 46,
      featureName: "INVOICE_APPROVAL",
      to: ROUTES.INVOICE_APPROVAL,
      title: Strings("invoiceApprovalFeatureTitle"),
      icon: InvoiceApproval,
      activeIcon: InvoiceApproval,
      isActive: false,
      urls: [ROUTES.INVOICE_APPROVAL],
    },
    CLIENT_PORTAL: {
      key: 47,
      featureName: "CLIENT_PORTAL",
      to: ROUTES.CLIENT_PORTAL,
      title: Strings("CLIENT_PORTAL"),
      icon: ClientPortalIcon,
      activeIcon: ClientPortalIcon,
      isActive: false,
      urls: [ROUTES.CLIENT_PORTAL],
    },
    META_MAP: {
      key: 47,
      featureName: "META_MAP",
      to: ROUTES.META_MAP_CONFIGURATION,
      title: Strings("META_MAP_CONFIGURATION"),
      icon: MetaMapIcon,
      activeIcon: MetaMapIcon,
      isActive: false,
      urls: [ROUTES.META_MAP_CONFIGURATION],
    },
    BANK_DETAIL_CONFIGURATION: {
      key: 48,
      featureName: "BANK_DETAIL_CONFIGURATION",
      to: ROUTES.BANK_DETAIL_CONFIGURATION,
      title: Strings("BANK_DETAIL_CONFIGURATION"),
      icon: BankAccountConfiguration,
      activeIcon: BankAccountConfiguration,
      isActive: false,
      urls: [ROUTES.BANK_DETAIL_CONFIGURATION],
    },
    MANAGE_SUPPLIER: {
      key: 49,
      featureName: "MANAGE_SUPPLIER",
      to: ROUTES.MANAGE_SUPPLIER,
      title: Strings("MANAGE_SUPPLIER"),
      icon: SupplierDetails,
      activeIcon: SupplierDetails,
      isActive: false,
      urls: [ROUTES.MANAGE_SUPPLIER],
    },
    ADMINISTRATIVE_EXPENSE_DATA: {
      key: 50,
      featureName: "ADMINISTRATIVE_EXPENSE_DATA",
      to: ROUTES.ADMINISTRATIVE_EXPENSE_DATA,
      title: Strings("ADMINISTRATIVE_EXPENSE_DATA"),
      icon: AdministrativeExpense,
      activeIcon: AdministrativeExpense,
      isActive: false,
      urls: [ROUTES.ADMINISTRATIVE_EXPENSE_DATA],
    },
    CREDO_CONFIG: {
      key: 51,
      featureName: "CREDO_CONFIG",
      to: ROUTES.CREDO_CONFIG,
      title: Strings("CredoCall"),
      icon: CREDO_CONFIG_ICON,
      activeIcon: CREDO_CONFIG_ICON,
      isActive: false,
      urls: [ROUTES.CONFIGURATION_DETAILS, ROUTES.CREDO_CONFIG],
    },
  };
}

export default function SideBarMenu(sidebarKeys) {
  let sidebarMenu = [];
  let allSideBarMenuItems = SideBarMenuItems();

  sidebarKeys.forEach((element) => {
    sidebarMenu.push(allSideBarMenuItems[element]);
  });
  return sidebarMenu;
}

export const MenuGroups = {
  Dashboard: {
    icon: DashBoards,
    features: ["DASHBOARD", "SALES_EFFICIENCY_DASHBOARD"],
  },
  work: {
    icon: Work,
    features: [
      "DAILY_STATUS",
      "WORK_HISTORY",
      "WORKLOAD_MANAGEMENT",
      "COLLECTION_VERIFICATION",
    ],
  },
  mtp: {
    icon: MTP,
    features: [
      "MTP_APPROVAL",
      "MTP_COMPLAINT_PORTAL",
      "MTP_DASHBOARD",
      "MTP_LADDER",
      "MTP_PRODUCTS",
      "MTP_PROMOTIONS",
      "MTP_LOAN_REQUESTS",
      "MTP_SIGNUP",
      "MTP_MINIMART_REQUESTS",
      "MTP_PROMOTIONS_APPROVAL",
    ],
  },
  Loans: {
    icon: Loans,
    features: [
      "APPROVAL_DASHBOARD",
      "LOAN_HISTORY",
      "LOAN_PORTFOLIO_MANAGEMENT",
      "ACCOUNTING_OFFICER_DASHBOARD",
      "ANALYST_DASHBOARD",
      "OPERATIONS_OFFICER_DASHBOARD",
      "REWARD_REDEEM_APPROVAL",
    ],
  },
  minimarts: {
    icon: MinimartManagement,
    features: [
      "MINIMART_MANAGEMENT",
      "MINIMART_HISTORY", //remove when obsolete
      "MAP",
      "MINIMART_APPROVAL_DASHBOARD", //remove when obsolete
      "NEW_MINIMART_MANAGEMENT",
      "INVOICE_APPROVAL",
      "CLIENT_PORTAL"
    ],
  },
  management: {
    icon: Management,
    features: [
      "BRANCH_MANAGEMENT",
      "USER_MANAGEMENT",
      "ZONE_POSITION_MAPPING",
    ],
  },
  catalog: {
    icon: Catalog,
    features: [
      "MOTORCYCLE_CATALOG",
      "PRODUCT_CATALOG",
      "REWARD_CATALOG",
      "BANK_DETAIL_CONFIGURATION",
      "MANAGE_SUPPLIER"
    ],
  },
  ci: {
    icon: CI,
    features: [
      "CI_INTERFACE",
      "CI_WIRE_TRANSFER",
      "REMITTANCE_PAYMENT",
      "CI_COLLECTION_APP_LOAN_REQUEST"
    ],
  },
  Reports: {
    icon: Reports,
    features: ["CALL_NOTES", "REPORTS", "COMMISSION_SCHEME"],
  },
};
