import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Strings } from "../../resources/i18n/i18n";
// Component
import VerifyCollection from "./VerifyCollection";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

// Action
import {
  ViewReceiptAction,
  getMessengerDetailsForDepositAction,
  verifyDepositAction,
  getDepositedMinimartListForVerificationAction,
} from "./VerifyCollectionAction";
import { generatePaymentReceiptAction } from "../MinimartDetails/MiniMartDetailsAction";
import { setDateForMessengerAction } from "../DailyStatus/DailyStatusActions";
import { downloadDownpaymentReceiptAction } from "../AnalystApproval/AnalystApprovalAction";
import { setSearchFilterAction } from "../../constants/Action";
import fileDownload from "js-file-download";

import { MESSENGER_DETAILS_KEYS, COLLECTION_KEYS } from "./Constants";
// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
//Constants
import { ROUTES } from "../../constants/routeConstants";
import Utility from "../../utils/Utility";
import {
  SEARCH_FILTER_PAGES,
  COLLECTION_VERIFICATION_FILTER,
} from "../../constants/appConstants";
class VerifyCollectionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerified: false,
      isError: false,
      messengerDate: props.messengerDate,
      messengerDetails: props.messenger,
      messengerProfilePic:
        props.messenger && props.messenger.profileUrl,
      verificationTableData: [],
      messengerId: props.messenger !== null && props.messenger.userId,
      collections: null,
      receiptUrl: null,
      minimartData: null,
      successMessage: "",
    };
  }

  componentDidMount() {
    this.getMessengerDetailsForDeposit({
      messengerId: this.state.messengerId,
      date: this.state.messengerDate,
    });
  }

  componentWillUnmount() {
    this.props.setDateForMessengerAction("");
    if (
      this.props.searchPage ===
        SEARCH_FILTER_PAGES.COLLECTION_VERIFICATION &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.COLLECTION_VERIFICATION,
        { ...COLLECTION_VERIFICATION_FILTER }
      );
    }
  }

  onBackClick = () => {
    this.props.history.push(ROUTES.COLLECTION_VERIFICATION);
  };

  createDataOfCollectionView = (collectionData) => {
    let MessengersDetails = {};
    let collectionamounts = {};

    let tableData =
      collectionData.deposits !== null ? collectionData.deposits : [];
    MESSENGER_DETAILS_KEYS.forEach((singleKey) => {
      MessengersDetails[singleKey] = collectionData[`${singleKey}`];
    });
    COLLECTION_KEYS.forEach((collectionKey) => {
      collectionamounts[collectionKey] =
        collectionData[`${collectionKey}`];
    });
    this.setState({
      messengerDetails: MessengersDetails,
      collections: collectionamounts,
      verificationTableData: tableData,
    });
  };

  getMessengerDetailsForDeposit = (searchFilterObject) => {
    this.setState({ showLoadingModal: true });
    getMessengerDetailsForDepositAction(
      searchFilterObject,
      (responseData) => {
        if (responseData.status === OPERATION_STATUS.SUCCESS) {
          this.createDataOfCollectionView(responseData.data.data);
          this.setState({
            messengerDetails: responseData.data.data,
            numberOfPages: responseData.data.data.numberOfPages,
            errorMessage: "",
            showLoadingModal: false,
          });
        } else {
          this.setState({
            isError: true,
            showLoadingModal: false,
            errorMessage: Strings("SorryAnErrorHasOccurred"),
          });
        }
      }
    );
  };

  verifyDeposit = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
      isVerified: false,
      successMessage: "",
      errorMessage: "",
      isError: false,
    });
    verifyDepositAction(searchFilterObject, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            isVerified: true,
            errorMessage: "",
            successMessage: Strings("VerifiedSuccessfully"),
          },
          () => {
            //NOSONAR
            this.getMessengerDetailsForDeposit({
              messengerId: this.state.messengerId,
              date: this.state.messengerDate,
            });
          }
        );
      } else {
        this.setState({
          successMessage: responseData.error.message,
          isError: true,
          showLoadingModal: false,
          errorMessage: responseData.error.message,
        });
      }
    });
  };

  onViewReceiptClick = (documentId) => {
    this.setState({ showLoadingModal: true });
    ViewReceiptAction(documentId, (response) => {
      if (
        response.status === OPERATION_STATUS.SUCCESS &&
        response.data.data.url
      ) {
        let s3Url = null;
        if (response.data.data.url) {
          s3Url = Utility.getDecryptedData(response.data.data.url);
        }
        this.setState({
          showLoadingModal: false,
          receiptUrl: s3Url,
          errorMessage: "",
        });
      } else {
        this.setState({
          showLoadingModal: false,
          isError: true,
          errorMessage: response.error.message,
        });
      }
    });
  };

  getDepositedMinimartListForVerification = (depositId, date) => {
    this.setState({ showLoadingModal: true });
    getDepositedMinimartListForVerificationAction(
      { id: depositId, date: date },
      (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            minimartData: apiResponse.data.data.mn,
            errorMessage: "",
            showLoadingModal: false,
          });
        } else {
          this.setState({
            errorMessage: Strings("ErrorInGettingMinimartInfo"),
            showLoadingModal: false,
          });
        }
      }
    );
  };

  viewReceipt = (receiptId) => {
    this.setState({ showLoadingModal: true, isSuccess: false });
    generatePaymentReceiptAction(receiptId, true, (response) => {
      if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
        const url = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = url;
        const filename = `${Strings(
          "PaymentReceiptFileName"
        )}${Utility.getReportFormattedDateString(
          Utility.convertDateIntoMiliSeconds(new Date())
        )}.pdf`; //or any other file extension
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
          isError: false,
        });
        link.click();
      } else {
        this.setState({
          showLoadingModal: false,
          isError: true,
          errorMessage: Strings("NoRecordsFound"),
        });
      }
    });
  };

  downloadDownpaymentReceipt = (receiptId, applicationId) => {
    this.setState({ showLoadingModal: true, isSuccess: false });
    downloadDownpaymentReceiptAction(receiptId, true, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
          fileDownload(
            response.data,
            `Recibo de Anticipo-${applicationId} ${Utility.getFileType(
              "pdf"
            )}`
          );

          this.setState({
            errorMessage: "",
            showLoadingModal: false,
            isError: false,
          });
        } else {
          this.setState({
            showLoadingModal: false,
            isError: true,
            errorMessage: Strings("NoRecordsFound"),
          });
        }
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  render() {
    return (
      <div>
        {this.state.isVerified && (
          <UserNotification
            key='VerifiedSuccessfully'
            userNotificationObj={{
              message: this.state.successMessage,
              level: "success",
            }}
          />
        )}
        {this.state.isError && (
          <UserNotification
            key='VerifyDepositError'
            userNotificationObj={{
              message: this.state.errorMessage,
              level: "error",
            }}
          />
        )}
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        <VerifyCollection
          state={this.state.initialData}
          viewDocument={this.viewDocument}
          applicationId={this.state.applicationId}
          onBackClick={this.onBackClick}
          errorMessage={this.state.errorMessage}
          onViewReceiptClick={this.onViewReceiptClick}
          receiptUrl={this.state.receiptUrl}
          messengerDetails={this.state.messengerDetails}
          data={this.state.verificationTableData}
          collectionData={this.state.collections}
          messengerProfilePic={this.state.messengerProfilePic}
          verifyDeposit={this.verifyDeposit}
          getDepositedMinimartListForVerification={
            this.getDepositedMinimartListForVerification
          }
          minimartData={this.state.minimartData}
          viewReceipt={this.viewReceipt}
          downloadDownpaymentReceipt={this.downloadDownpaymentReceipt}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedMessengerDetails: state.loanDetailsReducer,
  messenger: state.DailyStatusReducer.messenger,
  messengerDate: state.DailyStatusReducer.messengerDate,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setDateForMessengerAction, setSearchFilterAction },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyCollectionContainer);
