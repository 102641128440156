import React from "react";
import { NavLink } from "react-router-dom";
import { Popover } from "@mui/material";
import { Row } from "react-bootstrap";
import _ from "lodash";

//Assets
import { ReactComponent as Menu } from "../../resources/images/ic_menu.svg";
import logo from "../../resources/images/sidebarLogo.png";

// Constants
import {
  USER_ROLES,
  SEARCH_FILTER_PAGES,
  CONFIGURATION_FILTER,
  LOAN_APPROVAL_FILTER,
  OPERATIONS_LOAN_APPROVAL_FILTER,
} from "../../constants/appConstants";
import SideBarMenu, { MenuGroups } from "./sidebarConstants";
import { ROUTES } from "../../constants/routeConstants";
import { DEFAULT_PAGINATION_RECORDS_COUNT } from "../CustomUIComponents/Pagination/PaginationConstants";
import { APPLICATION_VERSION } from "../../constants/appGlobalConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./Sidebar.scss";
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      selectedGroup: null,
      anchorEl: null,
      open: false,
      features: {
        Dashboard: [],
        work: [],
        mtp: [],
        Loans: [],
        minimarts: [],
        management: [],
        catalog: [],
        ci: [],
        Reports: [],
      },
    };
    this.svgRefs = [];
    this.menuCloseSVGRef = "";
    this.menuSVGRef = "";
    this.sidebarItems = [];
    this.sidebarRef = React.createRef();
  }

  componentDidMount() {
    this.removeSVGTitle();
    this.props.selectedFeature.featureName === "" &&
      this.setDefaultFeature();
    this.sortFeatures();
  }

  componentDidUpdate(prevProps, prevState) {
    this.removeSVGTitle();
  }

  componentWillUnmount() {
    this.props.setSelectedFeatureAction(
      {
        activeIcon: "",
        featureName: "",
        icon: "",
        isActive: false,
        title: "",
        to: "",
      },
      (response) => {
        /* this is intentional */
      }
    );
  }

  removeSVGTitle = () => {
    this.svgRefs &&
      this.svgRefs.forEach((svgRef) => {
        let title =
          svgRef !== null && svgRef.getElementsByTagName("title");
        title && title[0] && svgRef.removeChild(title[0]);
      });
    if (this.menuCloseSVGRef) {
      let closeTitle =
        this.menuCloseSVGRef.getElementsByTagName("title");
      closeTitle &&
        closeTitle[0] &&
        this.menuCloseSVGRef.removeChild(closeTitle[0]);
    }
    if (this.menuSVGRef) {
      let MenuTitle = this.menuSVGRef.getElementsByTagName("title");
      MenuTitle &&
        MenuTitle[0] &&
        this.menuSVGRef.removeChild(MenuTitle[0]);
    }
  };

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
      selectedGroup: null,
      open: false,
      anchorEl: null,
    });
  };

  checkActiveLink = (Item) => {
    let path = "";
    if (
      // component accessible through multiple features
      this.props.location.pathname === ROUTES.CONFIGURATION_DETAILS || // all level 2 pages on configurator
      this.props.location.pathname ===
      ROUTES.APPROVAL_MINIMART_DETAILS ||
      this.props.location.pathname === ROUTES.MINIMART_DETAILS ||
      this.props.location.pathname === ROUTES.ANALYST_LOAN_VIEW ||
      this.props.location.pathname === ROUTES.ANALYST_LOAN_VIEW ||
      this.props.location.pathname ===
      ROUTES.LOAN_APPLICATION_DETAILS ||
      this.props.location.pathname ===
      ROUTES.VIEW_LOAN_APPLICATIONS ||
      this.props.location.pathname ===
      ROUTES.SALES_PERSON_WORK_HISTORY ||
      this.props.location.pathname === ROUTES.MESSENGER_WORK_HISTORY
    ) {
      path = this.props.selectedFeature?.to;
    } else {
      path = this.props.location.pathname;
    }
    return Item.urls.includes(path) || Item.to === path;
  };

  checkActiveGroup = (Item) => {
    return MenuGroups[Item].features.includes(
      this.props.selectedFeature?.featureName
    );
  };

  setActiveFeature = (Item) => {
    if (
      Item.featureName === "NEW_MINIMART_MANAGEMENT" &&
      this.props.selectedFeature &&
      this.props.selectedFeature.featureName === "MINIMART_MANAGEMENT"
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.NEW_MINIMART_LIST,
        {
          mid: "",
          code: "",
          mn: "",
          lvl: "",
          gr: "",
          toc: "",
          zn: "",
          mst: "ALL",
          st: "",
          bt: "",
          rs: "",
          zones: [],
          oc: "",
          isr: null,
          subType: null,
          limit: DEFAULT_PAGINATION_RECORDS_COUNT,
          offset: 1,
        }
      );
    } else if (
      Item.featureName === "MINIMART_MANAGEMENT" &&
      this.props.selectedFeature &&
      this.props.selectedFeature.featureName ===
      "NEW_MINIMART_MANAGEMENT"
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.MINIMART_LIST,
        {
          mid: "",
          code: "",
          mn: "",
          lvl: "",
          gr: "",
          toc: "",
          zn: "",
          mst: "ALL",
          st: "",
          bt: "",
          rs: "",
          oc: "",
          isr: null,
          subType: null,
          zones: [],
          limit: DEFAULT_PAGINATION_RECORDS_COUNT,
          offset: 1,
        }
      );
    } else if (
      Item.featureName === "ANALYST_DASHBOARD" &&
      this.props.selectedFeature &&
      this.props.selectedFeature.featureName ===
      "OPERATIONS_OFFICER_DASHBOARD"
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_APPROVAL,
        LOAN_APPROVAL_FILTER
      );
    } else if (
      Item.featureName === "OPERATIONS_OFFICER_DASHBOARD" &&
      this.props.selectedFeature &&
      this.props.selectedFeature.featureName === "ANALYST_DASHBOARD"
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.OPERATIONS_LOAN_APPROVAL,
        OPERATIONS_LOAN_APPROVAL_FILTER
      );
    }
    this.props.setSelectedFeatureAction(Item, (response) => {
      this.setState({
        showMenu: false,
        selectedGroup: null,
        open: false,
        anchorEl: null,
      });
    });
  };

  setDefaultFeature = () => {
    let features = SideBarMenu(
      Object.keys(this.props.loggedInUserInfo?.features)
    );

    _.forEach(features, (feature) => {
      if (feature?.to === this.props.location.pathname) {
        this.setActiveFeature(feature);
        return false; //break loop
      }
      return null;
    });

    if (
      this.props.loggedInUserInfo.role === USER_ROLES.configurator
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.CONFIGURATION,
        {
          ...CONFIGURATION_FILTER,
        }
      );
    }
  };

  // #region
  sortFeatures = () => {
    let features = this.state.features;
    this.sidebarItems.forEach((feature) => {
      //NOSONAR
      if (feature) {
        if (
          MenuGroups.Dashboard.features.includes(feature.featureName)
        ) {
          features.Dashboard.push(feature);
        }
        if (MenuGroups.work.features.includes(feature.featureName)) {
          features.work.push(feature);
        }
        if (MenuGroups.mtp.features.includes(feature.featureName)) {
          features.mtp.push(feature);
        }
        if (MenuGroups.Loans.features.includes(feature.featureName)) {
          features.Loans.push(feature);
        }
        if (
          MenuGroups.minimarts.features.includes(feature.featureName)
        ) {
          features.minimarts.push(feature);
        }
        if (
          MenuGroups.management.features.includes(feature.featureName)
        ) {
          features.management.push(feature);
        }
        if (
          MenuGroups.catalog.features.includes(feature.featureName)
        ) {
          features.catalog.push(feature);
        }
        if (MenuGroups.ci.features.includes(feature.featureName)) {
          features.ci.push(feature);
        }
        if (
          MenuGroups.Reports.features.includes(feature.featureName)
        ) {
          features.Reports.push(feature);
        }
      }
    });
    this.setState({
      features,
    });
  };

  renderGroupedMenu = () => {
    let groups = Object.keys(MenuGroups);
    return (
      <React.Fragment>
        <ul>
          {
            groups?.map((Item, index) => {
              const renderTitle =
                this.props.loggedInUserInfo.role ===
                  USER_ROLES.operationsModerator && Item === "ci"
                  ? Strings("fundTransfer")
                  : Strings(Item).toUpperCase();
              return (
                this.state.features[Item].length > 0 && (
                  <li key={this.state.features[Item].key} id={index}>
                    <NavLink
                      to='#'
                      title={renderTitle}
                      activeClassName='active-nav'
                      className={`groupHeader customNavlink ${(this.state.selectedGroup === Item &&
                        "groupHeaderActive") ||
                        (this.state.selectedGroup && "blurContents")
                        } ${this.state.showMenu && "width25"}`}
                      isActive={() => this.checkActiveGroup(Item)}
                      onClick={() =>
                        this.setState({
                          selectedGroup: Item,
                          showMenu: true,
                          anchorEl: document.getElementById(index),
                          open: true,
                        })
                      }
                    >
                      <div className='menuIconContainer '>
                        <img
                          src={MenuGroups[Item].icon}
                          alt=''
                          className='menuIcon'
                        />
                      </div>
                      {this.state.showMenu && (
                        <div className='menuTitleContainer'>
                          {renderTitle}
                        </div>
                      )}
                      {this.state.showMenu && (
                        <i className='fa fa-chevron-right' />
                      )}
                    </NavLink>
                  </li>
                )
              );
            })}
        </ul>
        <div>{this.renderPopupMenu()}</div>
      </React.Fragment>
    );
  };

  renderPopupMenu = () => {
    return (
      <Popover
        anchorEl={this.state.anchorEl}
        open={this.state.open}
        id={this.state.open ? "simple-popover" : undefined}
        onClose={() =>
          this.setState({
            open: false,
            anchorEl: null,
            selectedGroup: null,
          })
        }
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <ul type='none'>
          {this.state.features[this.state.selectedGroup]?.map(
            (Item) => {
              return (
                <li key={Item.title} className='expandedTitles'>
                  <NavLink
                    to={Item.to}
                    title={Item.title}
                    activeClassName='active-nav'
                    isActive={() => this.checkActiveLink(Item)}
                    onClick={() => this.setActiveFeature(Item)}
                  >
                    <div className='popupMenuIconContainer'>
                      <img
                        src={Item.icon}
                        alt=''
                        className='popupMenuIcon'
                      />
                    </div>
                    <div className='menuTitleContainer'>
                      {Item.title}
                    </div>
                  </NavLink>
                </li>
              );
            }
          )}
        </ul>
      </Popover>
    );
  };

  renderUngroupedMenu = () => {
    let className = this.state.showMenu ? "width25" : "";
    return (
      <ul>
        {this.sidebarItems !== null &&
          this.sidebarItems.map((Item, index) => {
            return Item ? (
              <li key={Item.title}>
                <NavLink
                  to={Item.to}
                  title={Item.title}
                  activeClassName='active-nav'
                  className={className}
                  isActive={() => this.checkActiveLink(Item)}
                  onClick={() => this.setActiveFeature(Item)}
                >
                  <div className='menuIconContainer'>
                    <img
                      src={Item.icon}
                      alt=''
                      className='menuIcon'
                    />
                  </div>
                  {this.state.showMenu && (
                    <div className='menuTitleContainer'>
                      {Item.title}
                    </div>
                  )}
                </NavLink>
              </li>
            ) : null;
          })}
      </ul>
    );
  };
  // #endregion

  render() {
   
    this.sidebarItems =
      this.props.loggedInUserInfo &&
      SideBarMenu(Object.keys(this.props.loggedInUserInfo.features));
    this.sidebarItems &&
      this.sidebarItems.sort((a, b) => a.key - b.key);
    return (
      <React.Fragment>
        <nav
          ref={this.sidebarRef}
          className={
            (this.state.showMenu
              ? "sidebarMenuExpanded width25"
              : "sidebarMenuCollapsed") + " sidebar"
          }
        >
          {this.state.showMenu && (
            <Row
              className='MatricesLogoContainer'
              onClick={() => this.toggleMenu()}
            >
              <img
                src={logo}
                alt='matricesLogo'
                className='quisqueyaLogo w-auto ps-0'
              />
            </Row>
          )}
          <hr className='sidebarHr opacityUnset opacity-100' />
          {!this.state.showMenu && (
            <Menu
              onClick={this.toggleMenu}
              className='menuIcon menuHeader'
              ref={(ref) => (this.menuSVGRef = ref)}
            />
          )}
          <div
            className={`menuScroll ${this.state.showMenu ? "d-flex" : ""
              }`}
          >
            {this.props.loggedInUserInfo.role ===
              USER_ROLES.configurator
              ? this.renderUngroupedMenu()
              : this.renderGroupedMenu()}
          </div>
          {this.state.showMenu ? (
            <div className='UIVersion'>v{APPLICATION_VERSION}</div>
          ) : (
            ""
          )}
        </nav>
        {this.state.showMenu && (
          <div
            className='backdropCover'
            style={{
              height:
                document.getElementsByClassName("layout")[0]
                  .scrollHeight,
            }}
            onClick={this.toggleMenu}
          ></div>
        )}
      </React.Fragment>
    );
  }
}

export default Sidebar;
