import { Strings } from "../../resources/i18n/i18n";

export const DISCOUNT_HISTORY_COLUMNS = [
  { key: "po-act", name: Strings("po_act"), maxWidth: 200, minWidth: 100 },
  { key: "dt", name: Strings("DiscountDetails"), maxWidth: 400, minWidth: 400 },
  { key: "nt", name: Strings("Notes"), maxWidth: 300, minWidth: 300 },
  { key: "pb-rl", name: Strings("pb_rl"), maxWidth: 200, minWidth: 200 },
    { key: "idr", name: Strings("Action"), maxWidth: 100, minWidth: 100 },
  ];
  export const DISCOUNT_HISTORY = [
    { key: "po-act", name: Strings("po_act"), maxWidth: 200, minWidth: 100 },
    { key: "dt", name: Strings("DiscountDetails"), maxWidth: 400, minWidth: 400 },
    { key: "nt", name: Strings("Notes"), maxWidth: 300, minWidth: 300 },
    { key: "pb-rl", name: Strings("pb_rl"), maxWidth: 190, minWidth: 190 },
  
    ];
 export const DISCOUNT_FIELDS = ["ne", "ti", "ipe"];

 export const DISCOUNT_HISTORY_FIELDS = {
  HISTORY_DATA_FIELDS: {
    sumkeyArray: [
      "id",
      ["po","act"],
      "dt",
      "nt",
      ["pb","rl"],
      "idr",
    ]
  }
 }