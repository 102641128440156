//Components
import React, { Component } from "react";
import ComplaintPortal from "./ComplaintPortal.js";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import withHOC from "../../HOC/HOC";

//actions
import {
  getComplaintPortalAction,
  resolveComplaintAction,
} from "./ComplaintManagementActions";

// String
import { Strings } from "../../resources/i18n/i18n";

//Constant
import { OPERATION_STATUS } from "../../config/axios.init";
import Utility from "../../utils/Utility.js";
import { dowloadedAction } from "../../constants/Action.js";
import fileDownload from "js-file-download";
class ComplaintPortalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      errorMessage: "",
      showLoadingModal: false,
      numberOfPages: 0,
      showToast: false,
      toastMessage: "",
    };
  }

  // on click of GO button
  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
    });
    getComplaintPortalAction(searchFilterObject, (responsedata) => {
      if (responsedata.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          numberOfPages: responsedata.data.data.np,
          rows: responsedata.data.data.rp,
          errorMessage: "",
          showLoadingModal: false,
        });
      } else {
        this.setState({
          errorMessage: responsedata.error.message,
          rows: [],
          showLoadingModal: false,
        });
      }
    });
  };

  // to resolve a complaint
  addNote = (noteId, note, successCallback) => {
    this.setState({
      showLoadingModal: true,
    });
    let reqObj = { id: noteId, nt: note };
    resolveComplaintAction(reqObj, (responsedata) => {
      if (responsedata.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showToast: true,
            toastMessage: Strings("resolvedSuccesfully"),
            level: "success",
            showLoadingModal: false,
            errorMessage: "",
          },
          successCallback
        );
      } else {
        this.setState(
          {
            errorMessage: responsedata.error.message,
            showLoadingModal: false,
          },
          successCallback
        );
      }
    });
  };

   downloadComplaint = (searchFilterObject) => {
        this.setState({ showLoadingModal: true, showToast: false });
          dowloadedAction(
          "",
          searchFilterObject,
            (response) => {
                if (response.status === OPERATION_STATUS.SUCCESS) {
                    if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
                        fileDownload(
                            response.data,
                            Strings("complaintReport") +
                            "-" +
                            Utility.getReportFormattedDateString(
                                Utility.convertDateIntoMiliSeconds(new Date())
                            ) +
                            Utility.getFileType(searchFilterObject.ft)
                        );
                        this.setState({
                          showLoadingModal: false,
                        });
                    }
                    else {
                        this.setState({
                          showLoadingModal: false,
                            showToast: true,
                            level: "error",
                            toastMessage: Strings("NoRecordsFound"),
                        })
                    }
                }
                else {
                    this.setState({
                        showLoadingModal: false,
                        toastMessage: response.error.message,
                    })
                }
            },
            "complaintReports"
        )
    }

  render() {
    return (
      <>
        {/* spinner */}
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        {this.state.showToast && (
          <UserNotification
            key={this.state.toastMessage}
            userNotificationObj={{
              message: Strings(this.state.toastMessage),
              level: this.state.level,
            }}
          />
        )}
        {/* Child component */}
        <ComplaintPortal
          rows={this.state.rows}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          downloadComplaint={this.downloadComplaint}
          addNote={this.addNote}
          showLoadingModal={this.state.showLoadingModal}
          errorMessage={this.state.errorMessage}
          //Pagination
          numberOfPages={this.state.numberOfPages}
          loggedInUser={this.props.loggedInUser}
        />
      </>
    );
  }
}

const ComplaintPortalWarapper = withHOC(ComplaintPortalContainer);

export default ComplaintPortalWarapper;
