//Components
import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList } from "react-tabs";
import CardActions from "@mui/material/CardActions";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import moment from "moment";

//Custom Components
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";

//Constants
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DATE_TYPE,
  DEFAULT_DATE_FORMAT,
  INVOICE_DATE_FORMAT,
  DEFAULT_DURATION,
  USER_ROLES,
  DOWNLOAD_FILE_TYPE,
} from "../../constants/appConstants";
import {
  PORTAL_COLUMNS,
  HISTORY_COLUMNS,
  COMPLAINT_TABS,
  PORTAL_SECOND_ROW,
  HISTORY_SECOND_ROW,
  COMPLAINTS,
} from "./Constants.js";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//icons
import PDFdownload from "../../resources/images/ic_download_pdf.svg";
import CSVdownload from "../../resources/images/ic_download_csv.svg";

class ComplaintPortal extends Component {
  constructor(props) {
    super(props);
    //Table columns for complaint portal and complaint history
    this.portalColumns = PORTAL_COLUMNS;
    this.historyColumns = HISTORY_COLUMNS;
    this.portalSecondRow = PORTAL_SECOND_ROW;
    this.historySecondRow = HISTORY_SECOND_ROW;
    this.state = {
      note: "",
      filters: {
        complaint: "",
        raisedBy: "",
        from: DEFAULT_DURATION.fromDate,
        to: DEFAULT_DURATION.toDate,
      },
      Tabs: COMPLAINT_TABS,
      activeTab: COMPLAINT_TABS[0],
      notesModal: false,
      expanded: [],

      //Pagination
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
    };
  }

  //On page load
  componentDidMount() {
    this.handleOnClickSearchFilter(true, this.state.activeTab.key, {
      complaint: "",
      raisedBy: "",
      from: DEFAULT_DURATION.fromDate,
      to: DEFAULT_DURATION.toDate,
    });
  }
  downloadFile = (type) => {
    const searchFilterObject = {
      rpby: this.state.filters.raisedBy,
      fd: this.state.filters.from
        ? Utility.convertDateIntoMiliSeconds(this.state.filters.from)
        : undefined,
      td: this.state.filters.to
        ? Utility.convertDateIntoMiliSeconds(this.state.filters.to)
        : undefined,
      cmp: this.state.filters.complaint === Strings("complaint")
        ? ""
        : this.state.filters.complaint,
      ft: type,
    }
    if (this.state.activeTab.key === 0) {
      searchFilterObject["st"] = "OPEN";
    } else {
      searchFilterObject["st"] = "RESOLVED";
    }
    this.props.downloadComplaint(searchFilterObject);
  }
  handleOnClickSearchFilter = (isDefaultOffset, tab, filters) => {
    isDefaultOffset &&
      this.setState({
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      });
    let searchFilterObject;
    searchFilterObject = {
      rpby: filters.raisedBy,
      fd: filters.from
        ? Utility.convertDateIntoMiliSeconds(filters.from)
        : undefined,
      td: filters.to
        ? Utility.convertDateIntoMiliSeconds(filters.to)
        : undefined,
      cmp:
        filters.complaint === Strings("complaint")
          ? undefined
          : filters.complaint,
    };

    //set the status of the complaint
    if (tab === 0) {
      searchFilterObject["st"] = "OPEN";
    } else {
      searchFilterObject["st"] = "RESOLVED";
    }
    //Pagination
    searchFilterObject["lmt"] = this.state.recordsPerPage;
    searchFilterObject["oft"] = isDefaultOffset
      ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
      : this.state.activePage - 1;

    this.props.handleOnClickSearchFilter(searchFilterObject);
  };

  handleSearchDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.filters.to)) {
          DateValue = this.state.filters.from;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        this.setState({
          filters: {
            ...this.state.filters,
            from: DateValue,
          },
        });
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.filters.from)) {
          DateValue = this.state.filters.to;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        if (dateMoment.isAfter(new Date())) {
          DateValue = new Date();
        }
        this.setState({
          filters: {
            ...this.state.filters,
            to: DateValue,
          },
        });
        break;
      default:
    }
  };

  handleSearchNameChange = (e) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [e.target.name]: e.target.value,
      },
    });
  };

  renderSearchFilter = (index) => {
    let searchFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSearchNameChange,
        Value: this.state.filters.complaint,
        fieldname: SEARCH_FILTER_FIELDS.COMPLAINT,
        filterDropdownList: COMPLAINTS,
        name: "complaint",
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("reportedBy"),
        handleOnChange: this.handleSearchNameChange,
        Value: this.state.filters.raisedBy,
        fieldname: SEARCH_FILTER_FIELDS.NAME,
        name: "raisedBy",
      },
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleSearchDateChange,
        fromDate: this.state.filters.from,
        handleCalenderChange: this.handleSearchDateChange,
        toDate: this.state.filters.to,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
    ];
    return (
      <SearchFilter
        filters={searchFilters}
        handleOnClickSearchFilter={() =>
          this.handleOnClickSearchFilter(true, index, {
            ...this.state.filters,
          })
        }
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingLeft={2}
      />
    );
  };

  //handle expand row icon click
  handleExpandClick = (id) => {
    let expandIndexId = this.state.expanded;
    const index = expandIndexId.indexOf(id);
    expandIndexId.includes(id)
      ? expandIndexId.splice(index, 1)
      : expandIndexId.push(id);
    this.setState({
      expanded: expandIndexId,
    });
  };

  //render css
  renderSpan = (columns, column) => {
    let span;
    if (columns === PORTAL_COLUMNS) {
      if (column.key === "cmp") {
        span = { span: 4 };
      } else if (column.key === "action") {
        span = { span: 2 };
      } else {
        span = { span: 2 };
      }
    } else if (columns === HISTORY_COLUMNS) {
      if (column.key === "cmp") {
        span = { span: 4 };
      } else {
        span = { span: 2 };
      }
    }
    return span;
  };

  //first row data
  renderFirstRowData = (row, column, columns, colIndex) => {
    const ExpandMore = styled((props) => {
      const { expand, ...other } = props;
      return <IconButton {...other} />;
    })(({ theme, expand }) => ({
      transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    }));

    let data;
    if (column.key === "cat") {
      data = moment(row[column.key]).format(INVOICE_DATE_FORMAT);
    } else if (columns.length - 1 === colIndex) {
      data = (
        <ExpandMore
          expand={this.state.expanded.includes(row.id)}
          onClick={() => this.handleExpandClick(row.id)}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </ExpandMore>
      );
    } else {
      data = Strings(row[column.key]);
    }
    return (
      <Col
        md={this.renderSpan(columns, column)}
        className={
          column.key === "action" ? "ms-0 text-center" : "ms-0"
        }
        title={Strings(column.name)}
        key={Math.random()}
      >
        <Typography className={"content"}>{data}</Typography>
      </Col>
    );
  };

  //On click of Resolve button
  onResolveClick = (id) => {
    this.setState({
      notesModal: true,
      notesId: id,
    });
  };

  //second row data(collapsible row)
  collapseSection = (row, secondRow) => {
    return (
      <Collapse
        id={Math.random()}
        in={this.state.expanded.includes(row.id)}
        timeout={200}
        unmountOnExit
      >
        <CardContent className='pt-0 arrowPointer tableRow'>
          <hr className={"my-2 opacity-100"} />
          <Row>
            {secondRow.map((column) => (
              <Col md={6} key={Math.random()} className='ps-0'>
                {column.key !== "btn" && row[column.key] && (
                  <span className={"label"}>
                    {Strings(column.name)}
                    {" : "}
                  </span>
                )}
                <span className={"content"}>
                  {column.key === "btn" ? (
                    <Col
                      md={{ offset: 9, span: 3 }}
                      className={this.props.loggedInUser.role === USER_ROLES.salesManager
                        ? 'cursorDisabled text-center'
                        : 'cursorPointer text-center'}
                      onClick={this.props.loggedInUser.role === USER_ROLES.salesManager
                        ? () => { }
                        : () => {
                          this.onResolveClick(row.id);
                        }}
                      title={Strings("resolveComplaintNotAllowed")}
                    >
                      {this.props.loggedInUser.role !== USER_ROLES.salesManager
                        ? <i
                          className='px-1 fa fa-check-circle'
                          aria-hidden='true'
                          title={Strings("resolve")}
                        />
                        : <i
                          className='px-1 fa fa-ban'
                          aria-hidden='true'
                          title={Strings("resolve")}
                        />}
                      <span className={this.props.loggedInUser.role === USER_ROLES.salesManager
                        ? "content url disabled"
                        : "content url textColor"}>
                        {Strings("resolve")}
                      </span>
                    </Col>
                  ) : (
                    Strings(row[column.key])
                  )}
                </span>
              </Col>
            ))}
          </Row>
        </CardContent>
      </Collapse>
    );
  };

  renderTable = (rows, columns, secondRow) => {
    return (
      <div>
        <Card className={"mt-3 headerRow py-2"}>
          <CardActions disableSpacing>
            {columns.map((column, colIndex) => {
              return (
                <Col
                  md={this.renderSpan(columns, column)}
                  key={Strings(column.name)}
                  className={
                    column.key === "action"
                      ? "ms-0 text-center"
                      : "ms-0"
                  }
                >
                  <Typography className={"headerContent"}>
                    {Strings(column.name)}
                  </Typography>
                </Col>
              );
            })}
          </CardActions>
        </Card>
        {rows.map((row, rowIndex) => {
          return (
            <Card
              id={Math.random()}
              className={
                this.state.expanded.includes(row.id)
                  ? "highlighted tableRow py-1"
                  : "tableRow py-1"
              }
              key={Math.random()}
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <CardActions>
                {columns.map((column, colIndex) => {
                  return this.renderFirstRowData(
                    row,
                    column,

                    columns,
                    colIndex
                  );
                })}
              </CardActions>

              {this.collapseSection(row, secondRow)}
            </Card>
          );
        })}
      </div>
    );
  };

  //render complaint portal and complaint history
  renderComplaints = (index) => {
    return index === 0
      ? this.renderTable(
        this.props.rows,
        this.portalColumns,
        this.portalSecondRow
      )
      : this.renderTable(
        this.props.rows,
        this.historyColumns,
        this.historySecondRow
      );
  };

  renderList = (index) => {
    return this.props?.rows.length > 0 ? (
      <>
        <Col md={12} className='my-3 px-3'>
          {this.renderComplaints(index)}
        </Col>
        {/* Pagination */}
        <Col md={12} className='pt-3'>
          <Pagination
            activePage={this.state.activePage}
            recordsPerPage={this.state.recordsPerPage}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.onPageChange}
            dropup={true}
          />
        </Col>
      </>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoRecordsFound")}
      </div>
    );
  };

  handleTabs = (index) => {
    this.setState(
      {
        activeTab: this.state.Tabs[index],
      },
      () => {
        this.handleOnClickResetFilter();
      }
    );
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        filters: {
          complaint: "",
          raisedBy: "",
          from: DEFAULT_DURATION.fromDate,
          to: DEFAULT_DURATION.toDate,
        },
        expanded: [],
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      },
      () => {
        this.handleOnClickSearchFilter(
          true,
          this.state.activeTab.key,
          this.state.filters
        );
      }
    );
  };

  toggleModal = () => {
    this.setState({
      note: "",
      notesModal: !this.state.notesModal,
    });
  };

  addNote = (note = null) => {
    this.setState(
      {
        note: note,
      },
      () => {
        this.props.addNote(
          this.state.notesId,
          this.state.note,
          () => {
            this.toggleModal();
            this.handleOnClickSearchFilter(
              false,
              this.state.activeTab.key,
              {
                ...this.state.filters,
              }
            );
          }
        );
      }
    );
  };

  //Pagination
  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        expanded: [],
        activePage: page,
        recordsPerPage: pageLength,
      },
      () =>
        this.handleOnClickSearchFilter(
          false,
          this.state.activeTab.key,
          {
            ...this.state.filters,
          }
        )
    );
  };
  renderPdfAndCsvOptions = () => {
    return (
      <Row className='pt-2 alignItemCenter'>
        <Col
          md={12}
          className='alignDownloadButtons'
        >
          <div className="pull-right">
            <Button
              className='downloadButtons me-3'
              onClick={() => {
                this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
              }}>
              <img src={PDFdownload} alt='' />
            </Button>
            <Button
              className='downloadButtons me-1'
              onClick={() => {
                this.downloadFile(DOWNLOAD_FILE_TYPE.CSV);
              }}>
              <img src={CSVdownload} alt='' />
            </Button>
          </div>
        </Col>
      </Row>
    )
  }
  render() {
    return (
      <div className='dailyStatus'>
        {this.props.errorMessage && (
          <Row className='errorText'>{this.state.errorMessage}</Row>
        )}
        {/* render notes modal to acccept notes while resolving the complaint */}
        {this.state.notesModal && (
          <NotesModal
            open={this.state.notesModal}
            note={this.state.note}
            modalHeader={Strings("Notes")}
            toggleModal={this.toggleModal}
            isNotesCompulsory={true}
            maxLength={500}
            addNote={this.addNote}
          />
        )}
        {/* render 2 tabs of complaint management */}
        <Tabs
          className='pt-3 '
          selectedIndex={this.state.activeTab.key}
          onSelect={this.handleTabs}
        >
          <TabList>
            {this.state.Tabs.map((item) => (
              <Tab key={item.key}>{item.value}</Tab>
            ))}
          </TabList>
          <div className=' loanList container-fluid TabListContainer customTableCard'>
            {/* search filter */}
            {this.renderSearchFilter(this.state.activeTab.key)}
            {/* download complaints report pdf and csv format  */}
            {this.renderPdfAndCsvOptions()}
            {/* complaint portal and complaint history table */}
            {this.renderList(this.state.activeTab.key)}
          </div>
        </Tabs>
      </div>
    );
  }
}

export default ComplaintPortal;
